import { gql, useLazyQuery } from '@apollo/client'
import React from 'react'
import _ from 'lodash'
import NumberFormat from 'react-number-format'
import Date from '../Date'
import Card from '../Card'

const FormatNumber = ({ value, decimalScale = 0 }) => {
  return <NumberFormat 
    value={value} 
    displayType={'text'} 
    thousandSeparator={true}
    decimalScale={decimalScale} 
    fixedDecimalScale
  />
}

const FormatCurrency = ({ value, decimalScale = 2 }) => {
  return <NumberFormat 
    value={value} 
    displayType={'text'} 
    thousandSeparator={true}
    prefix={'$'}
    decimalScale={decimalScale} 
    fixedDecimalScale
  />
}

const Metric = ({ label, value }) => {
  return (
    <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
      <dt className="order-1 mt-2 text-lg leading-6 font-sm text-gray-500">
        {label}
      </dt>
      <dd className="order-2 text-2xl font-extrabold text-indigo-600">
        {value}
      </dd>
    </div>
  )
}

const SectionHeader = ({ label }) => {
  return (
    <div className="rounded-md bg-blue-50 p-3 mb-4">
      <div className="flex">
        <div className="flex-1 md:flex md:justify-between">
          <p className="text-sm font-bold">
            {label}
          </p>
          {/* <p className="mt-3 text-sm md:mt-0 md:ml-6">
            <a href="#" className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">Details <span aria-hidden="true">&rarr;</span></a>
          </p> */}
        </div>
      </div>
    </div>
  )
}

function AdminManageProjectRunReport ({ project, autoRun }) {
  const [activeWeekId, setActiveWeekId] = React.useState('')

  const [getReport, {loading:getReportLoading, data:reportData}] = useLazyQuery(gql`
    query($project_id: uuid!){
      report(
        request:{
          project_id:$project_id
        }
      ){
        project
        reports
      }
    }
  `)

  React.useEffect(
    () => {
      if (reportData?.report?.reports?.length > 0) {
        setActiveWeekId(reportData.report.reports[0].id)
      }
    },
    [reportData, setActiveWeekId]
  )

  const pullData = React.useCallback(
    () => {
      setActiveWeekId(null)
      getReport({
        variables: {
          project_id: project.id
        }
      })
    },
    [project, getReport, setActiveWeekId]
  )


  React.useEffect(
    () => {
      if (autoRun) {
        pullData()
      } 
    },
    [autoRun, pullData]
  )

  const renderReport = React.useMemo(
    () => {
      if (!reportData || !activeWeekId) {
        return null
      }

      const { reports } = reportData.report
      const active = _.find(reports, { id: activeWeekId })

      const firstWeek = reports[reports.length - 1]

      console.log('renderReport activeWeekId = %s with active.id = %s', activeWeekId, active.id)
      return (
        <div>
          <div className="p-4 border-b flex-shrink flex space-x-3">
          {reports.map(week => {
            const isActive = (week.id === activeWeekId)
            return (
              <button 
                key={week.id}
                className={`rounded text-sm p-2 ` + (isActive ? "text-white bg-indigo-600" : "text-gray-500 bg-gray-100")}
                onClick={() => setActiveWeekId(week.id)}
              >
                {week.name}
              </button>
            )
          })}
          </div>

          <div className="flex w-full">
            <div className="px-8 py-6 flex-grow">
              <div className="">
                <div className="flex justify-between">
                  <h3 className="text-2xl font-extrabold mb-1">
                    {active.name}
                  </h3>
                </div>
                <div className="text-gray-400 text-sm mb-2">
                  This report includes data from <Date date={firstWeek.startDate} /> through <Date date={active.endDate} />
                </div>
              </div>
              
              <div className="mb-8">
                <div className="mx-auto">
                  <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-5">
                    <Metric label="Total Spend (USD)" value={<FormatCurrency value={active.totals.spend_usd} />} />
                    <Metric label="Total Clicks" value={<FormatNumber value={active.totals.clicks} />} />
                    <Metric label="Total Conversions" value={<FormatNumber value={active.totals.conversions} />} />
                    <Metric label="Adjusted Revenue (USD)" value={<FormatCurrency value={active.totals.adjusted_revenue_usd} />} />
                    {/* <Metric label="ROAS" value={<FormatNumber value={active.totals.roas} decimalScale={2} />} /> */}
                    <Metric label={
                      <div>
                        Adjusted ROAS&nbsp;
                        <span class="has-tooltip">
                          <span class="tooltip rounded text-sm shadow-lg p-3 bg-gray-100 -mt-4 text-sm" style={{ width: 400, marginLeft: -350, fontSize: 12}}>
                            Attribution loss can occur for several reasons, mainly tracking parameters being dropped from the URL or Facebook not being able to track pixel attribution due to the new IOS14 update. Campaigns lose an average of about 20 to 30 percent of tracked sales coming from Facebook ads. This calculation is based on revenue from direct sources and trackable revenue from ads. If tracking is lost the source will usually come through as direct, google, organic, etc. We are calculating this number based on <FormatCurrency value={active.totals.roasAdjustedAmount} /> revenue from direct sources, taking 25% of that revenue and applying it to the tracked revenue. For a more in-depth explanation please visit this blog post - <a href="https://enventyspartners.com/blog/ad-attribution-loss/" rel="noreferrer" target="_blank" style={{textDecoration:'underline'}}>https://enventyspartners.com/blog/ad-attribution-loss/</a>
                          </span>
                          <span style={{ fontWeight: 'bold' }}>?</span>
                        </span>
                      </div>
                    } value={<FormatNumber value={active.totals.roasAdjusted} decimalScale={2} />} />
                  </dl>
                </div>
              </div>

            {active.facebookCampaigns.length > 0 &&
              <>
                <SectionHeader label={`Facebook Campaigns`} />
                <div className="px-1">
                {active.facebookCampaigns.map((o, i) => (
                  <div key={i}>
                    <h5 className="text-lg mb-2">Campaign #{i+1} <span className="text-sm font-normal text-gray-400">({o.campaign_name})</span></h5>
                    <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mb-6">
                      <table className="report-table">
                        <thead>
                          <tr>
                            <th style={{ textAlign: 'left' }}>Audience Name</th>

                            <th width="90">
                              Status
                            </th>
                            <th width="110">
                              Daily Budget
                              <div className="currency">(USD)</div>
                            </th>

                            <th width="110"  className="border-l" >
                              Spend
                              <div className="currency">(USD)</div>
                            </th>
                            <th width="80">Clicks</th>
                            <th width="110" className="border-l" >
                              KS Revenue
                              <div className="currency">(USD)</div>
                            </th>
                            {/* <th width="110">
                              GA Revenue
                              <div className="currency">(USD)</div>
                            </th> */}
                            <th width="110">
                              FB Revenue
                              <div className="currency">(USD)</div>
                            </th>
                            <th className="border-l" width="130">
                              Total<br />
                              Conversions
                            </th>
                            <th width="140" style={{ textTransform: 'none' }}>
                              aRevenue
                              <div className="currency">(USD)</div>
                            </th>
                            {/* <th width="85">ROAS</th> */}
                            <th width="85" style={{ textTransform: 'none' }}>aROAS</th>
                          </tr>
                        </thead>
                        <tbody>
                        {o.audiences.map((o,i) => (
                          <tr key={i}>
                            <td style={{ textAlign: 'left' }}>{o.name}</td>

                            <td>
                              <span style={{fontSize: 12}}>
                                {o.effective_status === 'ACTIVE' 
                                  ? 'ACTIVE' 
                                  : !o.effective_status ? '' : 'PAUSED' }
                              </span></td>
                            <td>
                              {o.effective_status === 'ACTIVE' ? <FormatCurrency value={o.daily_budget} decimalScale={0} /> : ''}
                            </td>

                            <td className="border-l" ><FormatCurrency value={o.spend_usd} decimalScale={0} /></td>
                            <td><FormatNumber value={o.clicks} /></td>
                            <td className="border-l"><FormatCurrency value={o.ks_revenue_usd} decimalScale={0} /></td>
                            {/* <td><FormatCurrency value={o.ga_revenue_usd} decimalScale={0} /></td> */}
                            <td><FormatCurrency value={o.fb_revenue_usd} decimalScale={0} /></td>
                            <td className="border-l"><FormatNumber value={o.total_conversions} /></td>
                            <td><FormatCurrency value={o.adjusted_revenue} decimalScale={0} /></td>
                            {/* <td><FormatNumber value={o.roas} decimalScale={2} /></td> */}
                            <td><FormatNumber value={o.roas_adjusted} decimalScale={2} /></td>
                          </tr>
                        ))}
                        </tbody>
                        <tfoot>
                          <tr>
                            <td style={{ textAlign: 'left' }} className="font-bold">Totals</td>
                            <td>&nbsp;</td>
                            <td>&nbsp;</td>
                            <td className="border-l"><FormatCurrency value={o.totals.spend_usd} decimalScale={0} /></td>
                            <td><FormatNumber value={o.totals.clicks} /></td>
                            <td className="border-l"><FormatCurrency value={o.totals.ks_revenue_usd} decimalScale={0} /></td>
                            {/* <td><FormatCurrency value={o.totals.ga_revenue_usd} decimalScale={0} /></td> */}
                            <td><FormatCurrency value={o.totals.fb_revenue_usd} decimalScale={0} /></td>
                            <td className="border-l"><FormatNumber value={o.totals.total_conversions} /></td>
                            <td><FormatCurrency value={o.totals.adjusted_revenue} decimalScale={0} /></td>
                            {/* <td><FormatNumber value={o.totals.roas} decimalScale={2} /></td> */}
                            <td><FormatNumber value={o.totals.roas_adjusted} decimalScale={2} /></td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </div>
                ))}
                </div>
              </>
            }

            {active.googleYouTubeCampaigns.items.length > 0 &&
              <>
                <SectionHeader label="Google/YouTube Campaigns" />
                <div className="px-1">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mb-12">
                    <table className="report-table">
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'left' }}>Campaign</th>
                          <th width="110">
                            Spend
                            <div className="currency">(USD)</div>
                          </th>
                          <th width="80">Clicks</th>
                          <th className="border-l" width="130">
                            Total<br />
                            Conversions
                          </th>
                          <th width="140">
                            Total Revenue
                            <div className="currency">(USD)</div>
                          </th>
                          <th width="85">ROAS</th>
                        </tr>
                      </thead>
                      <tbody>
                      {active.googleYouTubeCampaigns.items.map(o => (
                        <tr key={o.campaign}>
                          <td style={{ textAlign: 'left' }}>{o.campaign}</td>
                          <td><FormatCurrency value={o.spend_usd} decimalScale={0} /></td>
                          <td><FormatNumber value={o.clicks} /></td>
                          <td className="border-l"><FormatNumber value={o.total_conversions} /></td>
                          <td><FormatCurrency value={o.total_revenue_usd} decimalScale={0} /></td>
                          <td><FormatNumber value={o.roas} decimalScale={2} /></td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td style={{ textAlign: 'left' }}>Totals</td>
                          <td><FormatCurrency value={active.googleYouTubeCampaigns.totals.spend_usd} decimalScale={0} /></td>
                          <td><FormatNumber value={active.googleYouTubeCampaigns.totals.clicks} /></td>
                          <td className="border-l"><FormatNumber value={active.googleYouTubeCampaigns.totals.total_conversions} /></td>
                          <td><FormatCurrency value={active.googleYouTubeCampaigns.totals.total_revenue_usd} decimalScale={0} /></td>
                          <td><FormatNumber value={active.googleYouTubeCampaigns.totals.roas} decimalScale={2} /></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </>
            }

            {active.otherGo2FundLinks.items.length > 0 &&
              <>
                <SectionHeader label="Other Go2.Fund Links" />
                <div className="px-1">
                  <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg mb-12">
                    <table className="report-table">
                      <thead>
                        <tr>
                          <th style={{ textAlign: 'left' }}>Link</th>
                          <th width="110">
                            KS Revenue
                            <div className="currency">(USD)</div>
                          </th>
                          {/* <th width="110">
                            GA Revenue
                            <div className="currency">(USD)</div>
                          </th> */}
                          <th className="border-l" width="130">
                            Total<br />
                            Conversions
                          </th>
                          <th width="140">
                            Total Revenue
                            <div className="currency">(USD)</div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                      {active.otherGo2FundLinks.items.map(o => (
                        <tr key={o.source}>
                          <td style={{ textAlign: 'left' }}>{o.source}</td>
                          <td><FormatCurrency value={o.ks_revenue_usd} decimalScale={0} /></td>
                          {/* <td><FormatCurrency value={o.ga_revenue_usd} decimalScale={0} /></td> */}
                          <td className="border-l"><FormatNumber value={o.total_conversions} /></td>
                          <td><FormatCurrency value={o.total_revenue_usd} decimalScale={0} /></td>
                        </tr>
                      ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td style={{ textAlign: 'left' }} className="font-bold">Totals</td>
                          <td><FormatCurrency value={active.otherGo2FundLinks.totals.ks_revenue_usd} decimalScale={0} /></td>
                          {/* <td><FormatCurrency value={active.otherGo2FundLinks.totals.ga_revenue_usd} decimalScale={0} /></td> */}
                          <td className="border-l"><FormatNumber value={active.otherGo2FundLinks.totals.total_conversions} /></td>
                          <td><FormatCurrency value={active.otherGo2FundLinks.totals.total_revenue_usd} decimalScale={0} /></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </>
            }

            </div>
          </div>
        </div>
      )
    },
    [reportData, activeWeekId]
  )

  return (
    <div className="bg-white border shadow-sm rounded mb-4">
      <div className="border-b px-4 py-4">
        <div className="flex justify-between align-center">
          <div className="text-lg font-medium text-gray-900">Weekly Report</div>
            <div className="flex space-x-2">
              <button type="button" className="btn" onClick={pullData}>
                {getReportLoading ? 'Loading...' : activeWeekId ? 'Refresh' : 'Run'}
              </button>
            </div>
        </div>
      </div>
      <div>
        {renderReport}
      </div>
    </div>
  )
}

export default AdminManageProjectRunReport 
