import React from 'react'
import { useHistory } from 'react-router-dom'

function Error ({ message }) {
  const history = useHistory()

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="text-center">
        <div className="text-3xl font-extrabold text-center font-normal text-gray-700">
          <div className="w-full text-red-200">
            <svg className="h-24 w-24 mx-auto" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
            </svg>
          </div>
          {message}
        </div>
        <button onClick={() => history.goBack()} className="text-sm text-indigo-600">&larr; Go back</button>
      </div>
    </div>
  )
}

export default Error
