import { gql, useQuery } from '@apollo/client'
import React from 'react'

const DashboardCard = ({ children }) => {
  return (
    <div className="relative overflow-hidden rounded-lg border border-gray-300 bg-white shadow-sm hover:border-indigo-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      {children}
    </div>
  )
}

function AdminIndex () {

  const { loading, data } = useQuery(gql`
    query{
      projects_aggregate{
        aggregate{
          count
        }
      }
      users_aggregate{
        aggregate{
          count
        }
      }
    }  
  `)
  
  if (loading) {
    return <div>...</div>
  }

  return (
    <div>

      <div className="mt-2">
        
        <div className="">
          <div className="relative">
            <div className="absolute inset-0 h-1/2 bg-gray-50"></div>
            <div className="relative">
              <div className=" mx-auto">
                <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-2">
                  <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                      Projects
                    </dt>
                    <dd className="order-1 text-5xl font-extrabold text-indigo-600">
                      {data.projects_aggregate.aggregate.count}
                    </dd>
                  </div>
                  <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                      Users
                    </dt>
                    <dd className="order-1 text-5xl font-extrabold text-indigo-600">
                      {data.users_aggregate.aggregate.count}
                    </dd>
                  </div>
                  {/* <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">
                      Last Updated
                    </dt>
                    <dd className="order-1 text-5xl font-extrabold text-indigo-600">
                      0
                    </dd>
                  </div> */}
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminIndex
