import React from 'react'
import Panel from '../Panel'
import Input from '../../components/Input'
import { gql, resetApolloContext, useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import generator from 'generate-password'

function AdminAddUser ({ onSave }) {
  const history = useHistory()
  const { register, handleSubmit, setValue, errors, reset } = useForm()
  const [show, setShow] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  
  const [registerUser] = useMutation(gql`
    mutation ($email:String!, $password:String!, $role:String!){
      register(
        email:$email
        password:$password
        role:$role
      ){
        user{
          id
        }
      }
    }
  `)


  const handleClose = React.useCallback(
  () => {
      setShow(false)
    },
    [setShow]
  )

  const onSubmit = React.useCallback(
    async (values) => {
      setLoading(true)
      try {
        const { data } = await registerUser({
          variables: {
            email: values.email,
            password: values.password,
            role: values.role
          }
        })
        setLoading(false)
        onSave(data.register.user)
        handleClose()
      } catch (e) {
        alert('An error occured while trying to save this user.  Please check the URL and try again.')
        setLoading(false)
      }
    },
    [setLoading, registerUser, onSave, handleClose]
  )

  React.useEffect(
    () => {
      if (show) {
        setValue('password', generator.generate({
          length: 8,
          numbers: true
        }))
      } else {
        reset()
      }
    },
    [show, setValue]
  )
  

  return (
    <div>
      <button type="button" className="btn" onClick={() => setShow(true)}>
        Add User
      </button>
      <Panel 
        title="Add User"
        show={show}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit(onSubmit)}>

          <div className="pb-4">
            <Input 
              type="email" 
              name="email" 
              label="Email" 
              autoComplete="off"
              ref={register({ required: true })}
            />
            {errors.email && <span>This field is required</span>}
          </div>

          <div className="pb-4">
            <Input 
              type="text" 
              name="password" 
              label="Password" 
              autoComplete="off"
              ref={register({ required: true })}
            />
            {errors.password && <span>This field is required</span>}
          </div>

          <div className="pb-4">
            <label className="block text-sm font-medium text-gray-700">Role</label>
            <select 
              defaultValue={"user"}
              name="role" className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              ref={register({ required: true })}
              >
              <option value="user">User</option>
              <option value="admin">Admin</option>
            </select>
            {errors.role && <span>This field is required</span>}
          </div>

          <div className="flex-shrink-0 border-gray-200 py-4">
            <div className="space-x-3 flex justify-end">
            {loading
              ? <div>Loading...</div>
              :
              <button type="submit" className="btn">
                Save
              </button>
            }
            </div>
          </div>
        </form>
      </Panel>
    </div>
  )
}

export default AdminAddUser
