import React from 'react'

function Card ({ title, renderAction, children }) {
  console.log('renderAction')
  console.log(renderAction)

  return (
    <div>
      <div className="bg-white border shadow-sm rounded mb-4">
        <div className="border-b px-4 py-4">
          <div className="flex justify-between align-center">
            <div className="text-lg font-medium text-gray-900">{ title }</div>
              <div className="flex space-x-2">
                {renderAction && renderAction()}
              </div>
          </div>
        </div>
        <div className="px-4 py-4">
          {children}
        </div>
      </div>
    </div>
    )
}

export default Card 
