import { gql, useMutation } from '@apollo/client'
import React from 'react'
import AdminManageGAView from './AdminManageGAView'
import NumberFormat from 'react-number-format'

function AdminManageProjectGAView ({ project, onClose }) {

  const [syncLoading, setSyncLoading] = React.useState(false)

  const [sync] = useMutation(gql`
    mutation ($projectId: uuid!) {
      sync_project_stats_google_analytics(projectId: $projectId) {
        success
      }
    }
  `)

  const handleSync = React.useCallback(
    async () => {
      if (window.confirm('This action will delete all GA data for this project and reload it using the current GA configuration.  OK?')) {
        setSyncLoading(true)
        const res = await sync({
          variables: {
            projectId: project.id
          }
        })
        console.log(JSON.stringify(res, null, 2))
        setSyncLoading(false)
        onClose()
        window.alert('GA data has been reloaded')
      }
    },
    [project, sync, setSyncLoading, onClose]
  )

  return (
    <div>
      <div className="bg-white border shadow-sm rounded mb-4">
        <div className="border-b px-4 py-4">
          <div className="flex justify-between align-center">
            <div className="text-lg font-medium text-gray-900">Google Analytics Integration</div>
            <div className="flex space-x-2">
              {/* {project.ga_details && 
                <button type="button" className="btn" onClick={pullData}>
                  {getReportLoading ? 'Loading...' : 'Pull Live Data'}
                </button>
              } */}
              <button type="button" className="btn" onClick={handleSync} disabled={syncLoading}>
                {syncLoading ? 'Loading...' : 'Full Sync'}
              </button>
              <AdminManageGAView project={project} onClose={onClose} />
            </div>
          </div>
        </div>
        <div className="px-4 py-4">
        {project.ga_details 
          ? 
            <div className="text-sm">
              <div className="">
                <span className="text-gray-500">GA Account:</span> {project.ga_details.account.name}
              </div>
              <div className="">
                <span className="text-gray-500">GA Property:</span> {project.ga_details.property.name} ({project.ga_details.property.id})
              </div>
              <div className="">
                <span className="text-gray-500">GA View:</span> {project.ga_details.view.name} ({project.ga_details.view.id})
              </div>
              <div className="">
                <span className="text-gray-500">Currency:</span> {project.ga_details.currency || <span className="italic">Will show after first sync</span>}
              </div>
              <div className="">
                <span className="text-gray-500">Go2.Links Source Filter:</span> {project.ga_details.go2_links_source_filter}
              </div>
              <div className="">
                <span className="text-gray-500">Google Ads Campaign Contains:</span> {project.ga_details.google_ads_campaign_filter || <span className="text-gray-800 italic">Not Specified</span>}
              </div>
              <div className="">
                <span className="text-gray-500">Google Ads Campaign Exact: </span> {project.ga_details.google_ads_campaign_exact ? <div><textarea className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-gray-600 border-gray-200" style={{ resize: 'none' }} rows={4} readOnly>{project.ga_details.google_ads_campaign_exact}</textarea></div> : <span className="text-gray-800 italic">Not Specified</span>}
              </div>
            </div>
          : 
            <div className="text-sm text-center text-gray-400">
              No Google Analytics View configured
            </div>
        }

        </div>
      </div>
    </div>
  )
}

export default AdminManageProjectGAView
