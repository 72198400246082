import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import useAuth, { AuthProvider } from './hocs/AuthProvider'
import ApolloProvider from './hocs/ApolloProvider'

const WaitForStateRehydration = ({ children }) => {
  const { isLoading } = useAuth()
  return !isLoading ? children : null
}

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <WaitForStateRehydration>
        <ApolloProvider>
          <App />
        </ApolloProvider>
      </WaitForStateRehydration>
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

