import React from 'react'
import { Link } from 'react-router-dom'
import AppLayout from './AppLayout'

const tabs = [
  { href: "/admin", label: "Dashboard" },
  { href: "/admin/projects", label: "Projects" },
  { href: "/admin/users", label: "Users" }
]

function AdminLayout ({ children }) {
  return (
    <AppLayout>
      <div className="bg-white border-b">
        <div className="container-fluid py-2">
          <div className="hidden sm:block">
            <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map(({ href, label }) => (
              <Link key={href} to={href} 
                className="text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-m"
              >
                {label}
              </Link>
            ))}
            </nav>
          </div>
        </div>
      </div>
      <div className="container-fluid py-4">
        {children}
      </div>
    </AppLayout>
  )
}

export default AdminLayout 
