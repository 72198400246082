import React from 'react'

export default React.forwardRef((props, ref) => {
  const classNames = ['shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md']
  if (props.disabled === true) {
    classNames.push('text-gray-400 border-gray-200')
  }
  return (
    <div>
      <label className="block text-sm font-medium text-gray-700">{props.label}</label>
      <div className="mt-1">
        <textarea ref={ref} className={classNames.join(' ')} {...props}></textarea>
      </div>
    </div>
  )
})