import React from 'react'
import Cookies from 'js-cookie'
import jwtDecode from 'jwt-decode'
import { gql } from '@apollo/client'
import { apolloClientFactory } from './ApolloProvider'

const AuthContext = React.createContext({})

const COOKIE_NAME = 'epjwtv2'

const AUTHENTICATE_MUTATION = gql`
  mutation($email:String!, $password:String!){
    authenticate(email:$email, password:$password){
      accessToken
    }
  }
`

const useProvideAuth = () => {
  const [token, setToken] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(true)
  
  React.useEffect(
    () => {
      const accessToken = Cookies.get(COOKIE_NAME)
      if (accessToken) {
        setToken(accessToken)
      }
      setIsLoading(false)
    },
    [setToken, setIsLoading]
  )

  const apolloClient = React.useMemo(
    () => apolloClientFactory({ token }),
    [token]
  )

  const signIn = async ({ email, password }) => {
    const { data } = await apolloClient.mutate({
      mutation: AUTHENTICATE_MUTATION,
      variables: {
        email,
        password
      }
    })

    const accessToken = data.authenticate.accessToken
    const claims = jwtDecode(accessToken)

    Cookies.set(COOKIE_NAME, accessToken, {
      expires: new Date(claims.exp * 1000)
    })

    setToken(accessToken)
    return {
      accessToken
    }
  }

  const signOut = async () => {
    Cookies.remove(COOKIE_NAME)
    setToken(null)
  }

  const tokenClaims = React.useMemo(
    () => token ? jwtDecode(token) : null,
    [token]
  )

  const user = React.useMemo(
    () => tokenClaims && tokenClaims.user,
    [tokenClaims]
  )

  return {
    token,
    tokenClaims,
    user,
    isLoading,
    isAuthenticated: !!token,
    signIn,
    signOut
  }
}

export const AuthProvider = ({ children }) => {
  const auth = useProvideAuth()

  return (
    <AuthContext.Provider value={auth}>
      {children}
    </AuthContext.Provider>
  )
}

export default function useAuth () {
  const context = React.useContext(AuthContext)
  return context
}