import React from 'react'
import { format, parseISO } from 'date-fns'

function Date ({ component:Component = 'span', date, format:formatString = 'MMM d yyy' }) {
  
  const formatted = React.useMemo(
    () => {
      if (typeof date === 'string') {
        return format(parseISO(date), formatString)
      } else {
        return format(date, formatString)
      }
    },
    [date, formatString]
  )

  return (
    <Component>
      {formatted}
    </Component>
  )
}

export default Date
