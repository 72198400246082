import React from 'react'
import DateFormatter from '../Date'
import AdminManageKickstarterView from './AdminManageKickstarterView'

function AdminManageProjectKickstarterView ({ project, onClose }) {

  return (
    <div>
      <div className="bg-white border shadow-sm rounded mb-4">
        <div className="border-b px-4 py-4">
          <div className="flex justify-between align-center">
            <div className="text-lg font-medium text-gray-900">Kickstarter Integration</div>
            <div className="flex space-x-2">
              <AdminManageKickstarterView project={project} onClose={onClose} />
            </div>
          </div>
        </div>
        <div className="px-4 py-4">
          <div className="text-sm">
            <div className="">
              <span className="text-gray-500">Start Date:</span> {project.ks_details && project.ks_details.start_date ? <DateFormatter date={project.ks_details.start_date} format="MM/dd/yyyy" /> : <span><DateFormatter date={new Date(project.data.launched_at * 1000)} format="MM/dd/yyyy"/> (Campaign Launch Date)</span> }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminManageProjectKickstarterView 
