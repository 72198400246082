import React from 'react'
import Panel from '../Panel'
import Input from '../../components/Input'
import { gql, useApolloClient, useMutation, useQuery } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'

function AdminAddProject() {
  const apolloClient = useApolloClient()
  const history = useHistory()
  const { register, handleSubmit, watch, errors } = useForm()
  const [show, setShow] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  
  const [trackProject] = useMutation(gql`
    mutation($url:String!, $google_service_account_id:Int){
      track_project(url:$url, google_service_account_id:$google_service_account_id){
        projectId
      }
    }
  `)

  const gaServiceAccountsQuery = useQuery(gql`
    query MyQuery {
      google_service_accounts(order_by: {order: asc}) {
        id
        email
        order
      }
    }
  `)

  // const onSubmit = React.useCallback(
  //   async (values) => {
  //     setLoading(true)

  //     // first off the async request
  //     const res = await trackProject({
  //       variables: {
  //         url: values.url
  //       }
  //     })

  //     console.log(res)

  //     // loop until its finished
  //     const id = res.data.track_project
  //     let result = false
  //     const sleep = (ms) => (new Promise(resolve => setTimeout(resolve, ms)))
  //     do {
  //       await sleep(1000)

  //       const updateRes = await apolloClient.query({
  //         query: gql`
  //           query ($id:uuid!){
  //             track_project(id: $id){
  //               id
  //               errors
  //               output{
  //                 projectId
  //               }
  //             }
  //           }
  //         `,
  //         variables: {
  //           id
  //         }
  //       })

  //       result = updateRes.data.track_project
        
  //     } while (!result.errors && !result.output.projectId)

  //     if (result.errors) {
  //       window.alert('An error occurred')
  //     } else {
  //       history.push(`/admin/projects/${result.output.projectId}`)
  //       setLoading(false)
  //     }

  //     // onClose()
  //   },
  //   [trackProject, setLoading, apolloClient, history]
  // )


  const onSubmit = React.useCallback(
    async (values) => {
      setLoading(true)
      try {
        const { data } = await trackProject({
          variables: {
            url: values.url,
            google_service_account_id: parseInt(values.google_service_account_id)
          }
        })
        history.push(`/admin/projects/${data.track_project.projectId}`)
        console.log(data)
      } catch (e) {
        alert('An error occured while trying to load this project.  Please check the URL and try again.')
        setLoading(false)
      }
    },
    [history, setLoading, trackProject]
  )

  const handleClose = React.useCallback(
  () => {
      setShow(false)
    },
    [setShow]
  )

  return (
    <div>
      <button type="button" className="btn" onClick={() => setShow(true)}>
        Add Project
      </button>
      <Panel 
        title="Add Project"
        show={show}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Input 
            type="text" 
            name="url" 
            label="Kickstarter Project URL" 
            autoComplete="off"
            ref={register({ required: true })}
          />
          
          
          <div className="mb-4 mt-4">
            <label htmlFor="google_service_account_id" className="block text-sm font-medium text-gray-700">Default Google Service Account {gaServiceAccountsQuery.loading ? ' (loading...)': ''}</label>
            <select 
              id="google_service_account_id" 
              name="google_service_account_id" 
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              ref={register({ required: true })}
            >
              <option value="">Choose a service account</option>
            {gaServiceAccountsQuery && gaServiceAccountsQuery.data &&
              gaServiceAccountsQuery.data.google_service_accounts.map(o => {
                return (
                  <option key={o.id} value={o.id} selected={o.order === 1}>
                    {o.email}
                  </option>
                )
              })
            }
            </select>
          </div>
          {errors.google_service_account_id && <span>This field is required</span>}
          

          <div className="flex-shrink-0 border-gray-200 py-4">
            <div className="space-x-3 flex justify-end">
            {loading
              ? <div>Loading...</div>
              :
              <button type="submit" className="btn">
                Save
              </button>
            }
            </div>
          </div>
        </form>
      </Panel>
    </div>
  )
}

export default AdminAddProject
