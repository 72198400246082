import React from 'react'
// import { CheckCircleIcon } from '../components/Icons'

function Alert ({ type = 'success', message, dismissible = false, ...props }) {
  const [visible, setVisible] = React.useState(true)

  React.useEffect(() => {
    setVisible(true)
  }, [message, setVisible])

  const color  = React.useMemo(
    () => {
      switch (type) {
        case 'error': 
          return 'red'
        case 'success':
        default:
          return 'green'
      }
    },
    [type]
  )

  const icon = React.useMemo(
    () => {
      switch (type) {
        case 'error':
          return (
            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          );
        default:
          return (
            <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
          )
      }
    },
    [type]
  )

  return (
    <div className={`rounded-md bg-${color}-50 p-4 ${!visible && 'hidden'}`} {...props}>
      <div className="flex">
        <div className={`flex-shrink-0 text-${color}-800`}>
          {icon}
        </div>
        <div className="ml-2">
          <p className={`text-sm font-medium text-${color}-800`}>
            {message}
          </p>
        </div>
        {dismissible && 
        <div className="ml-auto pl-3">
          <div className="-mx-1.5 -my-1.5">
            <button onClick={() => setVisible(false)} 
              className={`inline-flex bg-${color}-50 rounded-md p-1.5 text-${color}-500 hover:bg-${color}-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-${color}-50 focus:ring-${color}-600`}>
              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
              </svg>
            </button>
          </div>
        </div>
        }
      </div>
    </div>
  )
}

export default Alert
