import React from 'react'
import Panel from '../Panel'
import { useForm } from 'react-hook-form'
import Input from '../../components/Input'
import { gql, useMutation, useQuery } from '@apollo/client'
import _ from 'lodash'

import FacebookLogin from '@greatsumini/react-facebook-login'

function AdminManageFacebookView ({ project, onClose }) {
  const [fbData, setFbData] = React.useState(null)
  const [fbAdAccounts, setFbAdAccounts] = React.useState([])
  const [customConversions, setCustomConversions] = React.useState([])
  const [adsPixels, setAdsPixels] = React.useState([])

  const { register, handleSubmit, reset, errors, setValue, getValues } = useForm()
  const [show, setShow] = React.useState(false)
  const [loading] = React.useState(false)

  // const { loading: gaViewsLoading, data: gaViewsResponse } = useQuery(gql`
  //   query{
  //     ga_views{
  //       data
  //     }
  //   }
  // `, {
  //   skip: !show
  // })

  // React.useEffect(
  //   () => {
  //     if (project.ga_view && !gaViewsLoading) {
  //       setValue('view_id', project.ga_view.id)
  //       setValue('go2_links_source_filter', project.ga_view.go2_links_source_filter)
  //       setValue('google_ads_campaign_filter', project.ga_view.google_ads_campaign_filter)
  //     }
  //   },
  //   [show, project, setValue, gaViewsLoading]
  // )

  const [update] = useMutation(gql`
    mutation ($id:uuid!, $fb_details:jsonb){
      update_projects_by_pk(
        pk_columns:{id:$id}
        _set:{
          fb_details:$fb_details
        }
      ){
        id
      }
    }
  `)

  const [extendFbAccessToken] = useMutation(gql`
    mutation ($id:uuid!){
      extend_fb_access_token(project_id:$id){
        success
      }
    }
  `)

  const gaViewsLoading = false

  const handleClose = React.useCallback(
    () => {
      console.log('handleClose')
      setFbData(null)
      setFbAdAccounts([])
      setCustomConversions([])
      setAdsPixels([])
      setShow(false)
      onClose()
      reset()
    },
    [onClose, setShow, reset]
  )

  const onSubmit = React.useCallback(
    async (values) => {
      try {

        const adAccount = _.find(fbAdAccounts, o => o.id === values.ad_account_id)
        const customConversion = values.custom_conversion_id === 'purchase' ? { id: 'purchase', name: 'purchase' } : _.find(customConversions, o => o.id === values.custom_conversion_id)
        const adsPixel = _.find(adsPixels, o => o.id === values.ads_pixel_id)

        const fb_details = {
          account: {
            id: fbData.userID,
            accessToken: fbData.accessToken,
            lastRefreshed: null,
            expiresAt: Math.floor(Date.now()) + fbData.expiresIn,
          },
          adAccount,
          customConversion,
          campaignFilter: values.campaign_filter,
          adsPixel: adsPixel
        }

        await update({
          variables: {
            id: project.id,
            fb_details
          }
        })

        await extendFbAccessToken({
          variables: {
            id: project.id,
          }
        })

        handleClose()
      } catch (e) {
        console.log(e)
        alert('An error occured while trying to load this campaign.  Please check the URL and try again.')
      }
    },
    [fbData, fbAdAccounts, customConversions, adsPixels, handleClose, extendFbAccessToken, project, update]
  )
  
  const onFBLoginResponse = React.useCallback(
    (value) => {
      console.log(value)

      if (!value || value.status === 'unknown') {

        return
      }

      setFbData(value)
      window.FB.api('/me/adaccounts', 'GET', {fields: 'name', limit: 1000}, results => {
        if (results.error) {
          window.alert(`Error: ${results.error.message}`)
        } else {
          setFbAdAccounts(results.data)
        }

      })
    },
    [setFbData, setFbAdAccounts]
  )
  
  const handleAdAccountChange = React.useCallback(
    (event) => {
      console.log('handleAdAccountChange')
      
      window.FB.api(`/${event.target.value}/adspixels`, 'GET', {fields: 'id,name', limit: 1000}, results => {
        setAdsPixels(results.data)
      })

      window.FB.api(`/${event.target.value}/customconversions`, 'GET', {fields: 'id,name', limit: 1000}, results => {
        setCustomConversions(results.data)
      })
    },
    []
  )

  const handleCustomConversionChange = React.useCallback(
    (event) => {
      const customConversionId = event.target.value
      console.log('handleCustomConversionChange with %s', customConversionId)
    },
    []
  )

  if (gaViewsLoading) {
    return <div>...</div>
  }

  return (
    <div>
      <button type="button" className="btn" onClick={() => setShow(true)}>
        Configure
      </button>
    
      <Panel 
        title="Configure Facebook"
        show={show}
        onClose={handleClose}
      >
        
        <FacebookLogin
          appId="749422105965237"
          initParams={{
            version: 'v17.0'
          }}
          autoLoad={false}
          scope="public_profile,ads_read"
          fields="name,picture"
          onSuccess={onFBLoginResponse} />
        <form onSubmit={handleSubmit(onSubmit)}>

      {fbData && 
        <div>
          <div className="p4 mb-4">
            <div>Facebook connected to {fbData.userID}.</div>
          </div>
          <div className="mb-4">
            <label htmlFor="ad_account_id" className="block text-sm font-medium text-gray-700">Facbook Ad Accounts</label>
            <select 
              id="ad_account_id" 
              name="ad_account_id" 
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              onChange={handleAdAccountChange}
              ref={register({ required: true })}
            >
              <option value="">Choose an ad account</option>
            {fbAdAccounts.map(o => {
                return (
                  <option key={o.id} value={o.id}>{o.name}</option>
                )
              })
            }
            </select>
          </div>

          <div className="mb-4">
            <label htmlFor="ads_pixel_id" className="block text-sm font-medium text-gray-700">Ads Pixel (CAPI)</label>
            <select 
              id="ads_pixel_id" 
              name="ads_pixel_id" 
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              ref={register({ required: true })}
            >
              <option value="">Choose an ads pixel</option>
            {adsPixels.map(o => {
                return (
                  <option key={o.id} value={o.id}>{o.name}</option>
                )
              })
            }
            </select>
          </div>

        {customConversions.length > 0 && 
        <div>
          <div className="mb-4">
            <label htmlFor="custom_conversion_id" className="block text-sm font-medium text-gray-700">Custom Conversion</label>
            <select 
              id="custom_conversion_id" 
              name="custom_conversion_id" 
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              onChange={handleCustomConversionChange}
              ref={register({ required: true })}
              placeholder="Choose a conversion event"
            >
              <option value="purchase">Default "purchase"</option>
            {customConversions.map(o => {
                return (
                  <option key={o.id} value={o.id}>{o.name}</option>
                )
              })
            }
            </select>
          </div>
          <div className="mb-4">
            <Input 
              type="text" 
              name="campaign_filter" 
              label="Campaign Filter"
              autoComplete="off"
              ref={register({ required: false })}
            />
            {errors.campaign_filter && <span>This field is required</span>}
          </div>
        </div>
        }



          <button type="submit" className="btn">
            Save
          </button>
        </div>
      }

      </form>
      

{/*           
        <form onSubmit={handleSubmit(onSubmit)}>

          <div className="mb-4">
            <label htmlFor="view_id" className="block text-sm font-medium text-gray-700">Google Analytics View {gaViewsLoading ? ' (loading...)': ''}</label>
            <select 
              id="view_id" 
              name="view_id" 
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              ref={register({ required: true })}
            >
              <option value="">Choose a view</option>
            {gaViewsResponse && gaViewsResponse.ga_views &&
              gaViewsResponse.ga_views.data.map(o => {
                return (
                  <option key={o.id} value={o.id}>{o.name}</option>
                )
              })
            }
            </select>
          </div>

          <div className="mb-4">
            <Input 
              type="text" 
              name="go2_links_source_filter" 
              label="Go2.Links (Source/Medium Regex filter)"
              autoComplete="off"
              defaultValue={"go2.fund"}
              ref={register({ required: true })}
            />
            {errors.go2_links_source_filter && <span>This field is required</span>}
          </div>

          <div className="mb-4">
            <Input 
              type="text" 
              name="google_ads_campaign_filter" 
              label="Google Ads (Campaign Regex filter)"
              autoComplete="off"
              ref={register({ required: false })}
            />
            {errors.google_ads_campaign_filter && <span>This field is required</span>}
          </div>

          <div className="flex-shrink-0 border-gray-200 ">
            <div className="space-x-3 flex justify-between">
            {loading
              ? <div>Loading...</div>
              :
              <button type="submit" className="btn" disabled={gaViewsLoading}>
                Save
              </button>
            }
            </div>
          </div>
        </form> */}
      </Panel>
    </div>
  )
}

export default AdminManageFacebookView
