import { gql, useLazyQuery } from '@apollo/client'
import React from 'react'
import _ from 'lodash'
import NumberFormat from 'react-number-format'
import Date from './Date'
import Card from './Card'
import { useHistory } from 'react-router'

const FormatNumber = ({ value, decimalScale = 0 }) => {
  return <NumberFormat 
    value={value} 
    displayType={'text'} 
    thousandSeparator={true}
    decimalScale={decimalScale} 
    fixedDecimalScale
  />
}

const FormatCurrency = ({ value, decimalScale = 2 }) => {
  return <NumberFormat 
    value={value} 
    displayType={'text'} 
    thousandSeparator={true}
    prefix={'$'}
    decimalScale={decimalScale} 
    fixedDecimalScale
  />
}

const Metric = ({ label, value }) => {
  return (
    <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
      <dt className="order-1 mt-2 text-lg leading-6 font-sm text-gray-500">
        {label}
      </dt>
      <dd className="order-2 text-2xl font-extrabold text-indigo-600">
        {value}
      </dd>
    </div>
  )
}

const SectionHeader = ({ label }) => {
  return (
    <div className="rounded-md bg-blue-50 p-3 mb-4">
      <div className="flex">
        <div className="flex-1 md:flex md:justify-between">
          <p className="text-sm font-bold">
            {label}
          </p>
          {/* <p className="mt-3 text-sm md:mt-0 md:ml-6">
            <a href="#" className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">Details <span aria-hidden="true">&rarr;</span></a>
          </p> */}
        </div>
      </div>
    </div>
  )
}

function ReportSummary ({ project, autoRun, onViewFullReport }) {
  const [activeWeekId, setActiveWeekId] = React.useState('')
  const history = useHistory()

  const [getReport, {loading:getReportLoading, data:reportData}] = useLazyQuery(gql`
    query($project_id: uuid!){
      report(
        request:{
          project_id:$project_id
          summary: true
        }
      ){
        project
        reports
      }
    }
  `)

  React.useEffect(
    () => {
      if (reportData?.report?.reports?.length > 0) {
        setActiveWeekId(reportData.report.reports[0].id)
      }
    },
    [reportData, setActiveWeekId]
  )

  const pullData = React.useCallback(
    () => {
      setActiveWeekId(null)
      getReport({
        variables: {
          project_id: project.id
        }
      })
    },
    [project, getReport, setActiveWeekId]
  )

  React.useEffect(
    () => {
      if (autoRun) {
        pullData()
      } 
    },
    [autoRun, pullData]
  )

  const renderReport = React.useMemo(
    () => {
      if (!reportData || !activeWeekId) {
        return null
      }

      const { reports } = reportData.report
      const active = _.find(reports, { id: activeWeekId })

      const firstWeek = reports[reports.length - 1]

      return (
        <div className="flex w-full">
          <div className="px-0 py-6 flex-grow">
            <div className="mb-8">
              <div className="mx-auto">
                <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-5">
                  <Metric label="Total Spend (USD)" value={<FormatCurrency value={active.totals.spend_usd} />} />
                  <Metric label="Total Clicks" value={<FormatNumber value={active.totals.clicks} />} />
                  <Metric label="Total Conversions" value={<FormatNumber value={active.totals.conversions} />} />
                  <Metric label="Adjusted Revenue (USD)" value={<FormatCurrency value={active.totals.adjusted_revenue_usd} />} />
                  {/* <Metric label="ROAS" value={<FormatNumber value={active.totals.roas} decimalScale={2} />} /> */}
                  <Metric label={
                    <div>
                      Adjusted ROAS&nbsp;
                      <span class="has-tooltip">
                        <span class="tooltip rounded text-sm shadow-lg p-3 bg-gray-100 -mt-4 text-sm" style={{ width: 400, marginLeft: -350, fontSize: 12}}>
                          Attribution loss can occur for several reasons, mainly tracking parameters being dropped from the URL or Facebook not being able to track pixel attribution due to the new IOS14 update. Campaigns lose an average of about 20 to 30 percent of tracked sales coming from Facebook ads. This calculation is based on revenue from direct sources and trackable revenue from ads. If tracking is lost the source will usually come through as direct, google, organic, etc. We are calculating this number based on <FormatCurrency value={active.totals.roasAdjustedAmount} /> revenue from direct sources, taking 25% of that revenue and applying it to the tracked revenue. For a more in-depth explanation please visit this blog post - <a href="https://enventyspartners.com/blog/ad-attribution-loss/" rel="noreferrer" target="_blank" style={{textDecoration:'underline'}}>https://enventyspartners.com/blog/ad-attribution-loss/</a>
                        </span>
                        <span style={{ fontWeight: 'bold' }}>?</span>
                      </span>
                    </div>
                  } value={<FormatNumber value={active.totals.roasAdjusted} decimalScale={2} />} />
                </dl>
              </div>
            </div>

            <div className="text-gray-400 text-center text-sm mt-2">
              This report includes data from <Date date={project.data.launched_at * 1000} /> through <Date date={active.endDate} />
            </div>

          </div>
        </div>
      )
    },
    [project, reportData, activeWeekId]
  )

  return (
    <div className="mb-0">
      <div>
        <div className="flex justify-between align-center">
          <div className="text-lg font-medium text-gray-900">Performance Summary</div>
            <div className="flex space-x-2">
              {!getReportLoading && 
                <button type="button" className="btn" onClick={onViewFullReport}>
                  View Full Report
                </button>
              }
              <button type="button" className="btn" onClick={pullData}>
                {getReportLoading ? 'Loading...' : activeWeekId ? 'Refresh' : 'Run'}
              </button>
            </div>
        </div>
      </div>
      <div>
        {renderReport}
      </div>
    </div>
  )
}

export default ReportSummary 
