import { gql, useMutation, useQuery } from '@apollo/client'
import React from 'react'
import { Link } from 'react-router-dom'
import AdminAddUser from '../../components/Admin/AdminAddUser'
import Input from '../../components/Input'
import { debounce } from 'lodash'
import AdminEditUser from '../../components/Admin/AdminEditUser'

function AdminProjects () {
  const [filter, setFilter] = React.useState(null)
  const [selectedUser, setSelectedUser] = React.useState(null)

  const [deleteUserMutation] = useMutation(gql`
    mutation ($id:uuid!){
      delete_users_projects(
        where:{
          userId:{_eq:$id}
        }
      ){
        affected_rows
      }
      user:delete_users_by_pk(
        id:$id
      ){
        id
      }
    }
  `)


  const debouncedSave = React.useRef(
    debounce(
      (e) => {
        const value = e.target.value
        console.log('value = %s', value)
        if (value.length === 0) {
          setFilter(null)
        } else {
          setFilter(`%${value}%`)
        }
      },
      400
    )
  ).current

  const { data, loading, refetch } = useQuery(gql`
    query ($filter:String) {
      users(
        order_by: {email: asc}
        where: {
          email: {
            _ilike: $filter
          }
        }
      ){
        id
        email
        role
        users_projects{
          id
          project{
            id
            name
          }
        }
      }
    }
  `, {
    pollInterval: 30000,
    variables: {
      filter
    }
  })


  const deleteUser = React.useCallback(
    async (user) => {
      if (window.confirm('Are you sure you want to delete this user?')) {
        await deleteUserMutation({
          variables: {
            id: user.id
          }
        })
        refetch()
      }
    },
    [deleteUserMutation, refetch]
  )

  if (loading && !data) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <h1 className=" text-xl text-center sm:text-left leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl">Users</h1>
        <div>
          <AdminAddUser onSave={() => refetch()} />
        </div>
      </div>

      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="mb-3 grid grid-cols-1 space-x-4">
              <div><Input type="text" placeholder="Filter users by email..." onChange={debouncedSave} autoFocus /></div>
            </div>
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">


              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Email
                    </th>
                    <th scope="col" style={{ width: 120 }} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Projects
                    </th>
                    <th scope="col" style={{ width: 120 }} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Role
                    </th>
                    <th style={{ width: 120 }} scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
              {data.users.map((row) => (
                <tbody key={row.id} className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td className="px-6 py-4 text-sm whitespace-nowr  ap">
                      {row.email}
                    </td>
                    <td className="px-6 py-4 text-sm whitespace-nowrap">
                      {row.users_projects.length}
                    </td>
                    <td className="px-6 py-4 text-sm whitespace-nowrap">
                      {row.role}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <div className="flex space-x-3">
                        <button onClick={() => deleteUser(row)} className="text-indigo-600 hover:text-indigo-900">Delete</button>
                        <button onClick={() => setSelectedUser(row)} className="text-indigo-600 hover:text-indigo-900">Edit</button>
                      </div>
                    </td>
                  </tr>
                </tbody>
              ))}
              </table>
            </div>
          </div>
        </div>
      </div>

      <AdminEditUser 
        user={selectedUser} 
        onSave={() => refetch()}
        onClose={() => setSelectedUser(null)}
      />
    </div>
  )
}

export default AdminProjects
