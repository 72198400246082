import { gql, useMutation, useQuery } from '@apollo/client'
import React from 'react'
import Card from '../../components/Card'
import { Link, useHistory, useParams } from 'react-router-dom'
import AdminAssignUserToProject from '../../components/Admin/AdminAssignUserToProject'
import AdminManageProjectGAView from '../../components/Admin/AdminManageProjectGAView'
import AdminManageProjectFacebookView from '../../components/Admin/AdminManageProjectFacebookView'
import AdminManageProjectRunReport from '../../components/Admin/AdminManageProjectRunReport'
import AdminManageProjectSyncStats from '../../components/Admin/AdminManageProjectSyncStats'
import AdminManageProjectKickstarterView from '../../components/Admin/AdminManageProjectKickstarterView'
import DateFormatter from '../../components/Date'

function AdminViewProject () {
  const { id } = useParams()
  const history = useHistory()
  
  const { data: { project } = {}, loading, refetch } = useQuery(gql`
    query($id:uuid!){
      project:projects_by_pk(id:$id){
        id
        name
        data
        ga_details
        fb_details
        ks_details
        updatedAt
        state:data(path:"state")
        last_synced_at:data(path:"synced_at")
        google_service_account{
          id
          email
        }
        project_stats(
          limit: 10
          order_by:{date:desc}
        ){
          id
          date
          backers
          pledged
          source
        }
        users_projects{
          id
          user{
            id
            email
          }
        }

      }
    }
  `, {
    skip: !id,
    variables: {
      id
    }
  })

  console.log(project)

  const [deleteProjectMutation] = useMutation(gql`
    mutation ($id:uuid!) {
      delete_users_projects(
        where:{
          projectId:{_eq:$id}
        }
      ){
        affected_rows
      }
      
      delete_project_stats(
        where:{
          projectId:{_eq:$id}
        }
      ){
        affected_rows
      }

      delete_project_facebook_adset_stats(
        where:{
          project_id:{_eq:$id}
        }
      ){
        affected_rows
      }

      delete_project_facebook_adsets(
        where:{
          project_id:{_eq:$id}
        }
      ){
        affected_rows
      }

      delete_project_kickstarter_stats(
        where:{
          projectId:{_eq:$id}
        }
      ){
        affected_rows
      }

      delete_project_google_analytics_youtube_stats(
        where:{
          projectId:{_eq:$id}
        }
      ){
        affected_rows
      }

      delete_project_google_analytics_go2fund_stats(
        where:{
          projectId:{_eq:$id}
        }
      ){
        affected_rows
      }
      
      delete_projects_by_pk(
        id:$id
      ){
        id
      }
    }
  `)

  const [deleteUser] = useMutation(gql`
    mutation($id:uuid!){
      delete_users_projects_by_pk(id:$id){
        id
      }
    }
  `)

  const handleOpenDashboard = React.useCallback(
    () => {
      history.push(`/p/${project.id}`)
    },
    [history, project]
  )

  const handleOpenKickstarter = React.useCallback(
    () => {
      window.open(project.data.urls.web.project, '_blank')
    },
    [project]
  )

  const deleteProject = React.useCallback(
    async () => {
      if (window.confirm('Are you sure you want to delete this project and all associated data?  This includes all historical stats and associated users.')) {
        await deleteProjectMutation({
          variables: {
            id: project.id
          }
        })
        history.push('/admin/projects')
      }
    },
    [deleteProjectMutation, project, history]
  )

  const handleDeleteUser = React.useCallback(
    async (id) => {
      await deleteUser({
        variables:{
          id
        }
      })
      refetch()
    },
    [refetch, deleteUser]
  )

  const revokeUser = React.useCallback(
    (id) => {
      if (window.confirm('Are you sure you want the use from the project?')) {
        handleDeleteUser(id)
      }
    },
    [handleDeleteUser]
  )

  const snippet = React.useMemo(() => {
    if (!project) {
      return '';
    }
    
    return `<!-- START --> 
<link rel="stylesheet" href="https://embed.typeform.com/next/css/widget.css" />
<div 
    id="ep-tf-conv-container" 
    style="width: 100vw; height: 100vh;" 
    data-projectId="${project?.id}" 
    data-formId="[ENTER TYPEFORM FORM ID HERE]" 
    data-defaultPledgeAmount="54"
    data-defaultCurrency="USD" />
<script src="https://embed.typeform.com/next/embed.js"></script>
<script src="https://ep-dashboard-backend.herokuapp.com/js/main.js"></script>
<!-- END -->
`;
  }, [project])


  if (loading) {
    return <div>Loading...</div>
  }
  

  return (
    <div>
      <h1 className="mb-3 text-xl text-center sm:text-left leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl">
        {project.name}
      </h1>

      <div className="flex space-x-3 mb-4">
        <button className="btn" onClick={handleOpenDashboard}>Dashboard</button>
        <button className="btn" onClick={handleOpenKickstarter}>Open on Kickstarter</button>
        <button className="btn" onClick={deleteProject}>Delete</button>
      </div>

      {/* manage users */}
      <div className="grid grid-cols-2 gap-4">
        <div>
          <Card title="Project Details">
            <div>Launched <DateFormatter date={new Date(project.data.launched_at * 1000)} /></div>
            <div>Deadline <DateFormatter date={new Date(project.data.deadline * 1000)} /></div>
            <div>Currency: {project.data.currency}</div>
          </Card>
        </div>
        <div>
          <div className="bg-white border shadow-sm rounded mb-4">
            <div className="border-b px-4 py-4">
              <div className="flex justify-between align-center">
                <div className="text-lg font-medium text-gray-900">Manage Users</div>
                <div>
                  <AdminAssignUserToProject projectId={project.id} onClose={refetch} />
                </div>
              </div>
            </div>
            <div className="px-4 py-4">
              {project.users_projects.length === 0 && <div className="text-center text-gray-400 text-sm">No users have access to this project.  Only admins will see this project.</div>}
              {project.users_projects.map(o => {
                return (
                  <div key={o.id} className="text-sm">
                    <span className="mr-1 font-medium">{o.user.email}</span>
                    <a href="#" onClick={() => revokeUser(o.id)} className="text-sm text-indigo-600">(revoke)</a>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

      </div>

      <div className="grid grid-cols-2 gap-4">
        <AdminManageProjectGAView project={project} onClose={refetch} />
        <div>
          <AdminManageProjectKickstarterView project={project} onClose={refetch} />
          <AdminManageProjectFacebookView project={project} onClose={refetch} />
        </div>
      </div>

      <div>
        <div className="bg-white border shadow-sm rounded mb-4">
          <div className="border-b px-4 py-4">
            <div className="flex justify-between align-center">
              <div className="text-lg font-medium text-gray-900">Conversion Page Script Tag</div>
              {/* <div className="flex space-x-2">
                <button type="button" className="btn">Something</button>
              </div> */}
            </div>
          </div>
          <div className="px-4 py-4">        
            <div className="mb-2">
              Copy and paste this into the conversion page.  You can edit TypeFormID, default pledge amount, and currency within the script.  But don't change anything else!
            </div>
            <textarea value={snippet} onChange={()=>{}} rows={16} style={{ width: '100%', fontSize: 14, fontFamily: 'monospaced', lineHeight: 1 }}  readOnly />
            
          </div>
        </div>
      </div>

      <AdminManageProjectRunReport project={project} />



    </div>
  )
}

export default AdminViewProject
