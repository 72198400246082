import React from 'react'
import Panel from '../Panel'
import { useForm } from 'react-hook-form'
import Input from '../../components/Input'
import { gql, useMutation, useQuery } from '@apollo/client'
import _ from 'lodash'

function AdminManageKickstarterView ({ project, onClose }) {
  const [show, setShow] = React.useState(false)
  const { register, handleSubmit, reset, errors, setValue, getValues } = useForm()

  React.useEffect(
    () => {
      if (project.ks_details) {
        setValue('start_date', project.ks_details.start_date)
      }
    },
    [project, setValue]
  )

  const [update] = useMutation(gql`
    mutation ($id:uuid!, $ks_details:jsonb){
      update_projects_by_pk(
        pk_columns:{id:$id}
        _set:{
          ks_details:$ks_details
        }
      ){
        id
      }
    }
  `)

  const handleClose = React.useCallback(
    () => {
      setShow(false)
      onClose()
      reset()
    },
    [onClose, setShow, reset]
  )

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        await update({
          variables: {
            id: project.id,
            ks_details: {
              start_date: values.start_date
            }
          }
        })
        handleClose()
      } catch (e) {
        console.log(e)
        alert('An error occured while trying to load this campaign.  Please check the URL and try again.')
      }
    },
    [project, update, handleClose]
  )

  const handleReset = React.useCallback(
    (e) => {
      e.preventDefault()
      setValue('start_date', null)
      onSubmit({
        start_date: null
      })
    },
    [onSubmit, setValue]
  )

  return (
    <div>
      <button type="button" className="btn" onClick={() => setShow(true)}>
        Configure
      </button>
    
      <Panel 
        title="Configure Kickstarter"
        show={show}
        onClose={handleClose}
      >
        
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mb-4">
            <Input 
              type="date" 
              name="start_date" 
              label="Start Date"
              autoComplete="off"
              placeholder=""
              ref={register({ required: false })}
            />
            {errors.google_ads_campaign_filter && <span>This field is required</span>}
          </div>

          <div className="d-flex space-x-2">
            <button type="submit" className="btn">
              Save
            </button>
            <button className="btn bg-white" style={{backgroundColor: '#fff', color: '#333', border: '1px solid #333'}} onClick={handleReset}>
              Reset to Launch Date
            </button>
          </div>
        </form>
      </Panel>
    </div>
  )
}

export default AdminManageKickstarterView
