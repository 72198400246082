import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { Link } from 'react-router-dom'
import AdminAddProject from '../../components/Admin/AdminAddProject'
import Input from '../../components/Input'
import { debounce } from 'lodash'

function AdminProjects () {
  const [filter, setFilter] = React.useState(null)
  const [filterActive, setFilterActive] = React.useState(true)

  const debouncedSave = React.useRef(
    debounce(
      (e) => {
        const value = e.target.value
        console.log('value = %s', value)
        if (value.length === 0) {
          setFilter(null)
        } else {
          setFilter(`%${value}%`)
        }
      },
      400
    )
  ).current

  const { data, loading } = useQuery(gql`
    query ($filter:String, $enabled:Boolean) {
      projects(
        order_by: {createdAt: desc}
        where: {
          name: {
            _ilike: $filter
          }
          enabled: {
            _eq: $enabled
          }
        }
      ){
        id
        name
        ga_details
        fb_details
        # name:data(path:"name")
        photos:data(path:"photo")
        state:data(path:"state")
        last_synced_at:data(path:"synced_at")
        backer_report
        users_projects_aggregate{
          aggregate{
            count
          }
        }
      }
    }
  `, {
    pollInterval: 30000,
    variables: {
      filter,
      enabled: filterActive
    }
  })

  if (loading && !data) {
    return <div>Loading...</div>
  }

  return (
    <div>
      <div className="flex justify-between items-center mb-2">
        <h1 className=" text-xl text-center sm:text-left leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl">Projects</h1>
        <div>
          <AdminAddProject />
        </div>
      </div>

      <div className="flex space-x-4 mb-2">
        <div className="form-check form-check-inline">
          <input 
            className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 cursor-pointer" 
            type="radio" 
            id="inlineRadio1" 
            onChange={e => setFilterActive(true)}
            checked={filterActive === true}
          />
          <label className="form-check-label inline-block text-gray-800" htmlFor="inlineRadio1">Active</label>
        </div>
        <div className="form-check form-check-inline">
          <input 
            className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 cursor-pointer" 
            type="radio" 
            id="inlineRadio2" 
            onChange={e => setFilterActive(false)}
            checked={filterActive === false}
          />
          <label className="form-check-label inline-block text-gray-800" htmlFor="inlineRadio2">Archived</label>
        </div>
      </div>

      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="mb-3">
              <Input type="text" placeholder="Filter projects by name..." onChange={debouncedSave} autoFocus />
            </div>
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">


              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Project
                    </th>
                    {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Title
                    </th> */}
                    <th scope="col" style={{ width: 120 }} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Users
                    </th>
                    <th scope="col" style={{ width: 175 }} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Integrations 
                    </th>
                    <th scope="col" style={{ width: 150 }} className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Backer CSV 
                    </th>
                    {/* <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                      Role
                    </th> */}
                    <th style={{ width: 120 }} scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Manage</span>
                    </th>
                  </tr>
                </thead>
              {data.projects.map((row) => (
                <tbody key={row.id} className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <div className="flex-shrink-0 w-16">
                          <Link to={`/admin/projects/${row.id}`}><img className="h-8 w-full object-cover object-top" src={row.photos.full} alt="" /></Link>
                        </div>
                        <div className="ml-4">
                          <div className="text-sm font-medium text-gray-900">
                            <Link to={`/admin/projects/${row.id}`}>{row.name}</Link>
                          </div>
                          <div className="text-xs text-gray-500 capitalize">{row.state}</div>
                          {/* <div className="text-sm text-gray-500">
                            Deadline: {}
                          </div> */}
                        </div>
                      </div>
                    </td>
                    {/* <td className="px-6 py-4 whitespace-nowrap">
                      <div className="text-sm text-gray-900">Regional Paradigm Technician</div>
                      <div className="text-sm text-gray-500">Optimization</div>
                    </td> */}
                    <td className="px-6 py-4 text-sm whitespace-nowrap">
                      {row.users_projects_aggregate.aggregate.count}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex space-x-2">
                      {row.ga_details && <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 capitalize">Google</span> }
                      {row.fb_details && <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 capitalize">Facebook</span> }
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {row.backer_report
                        ? <a href={row.backer_report} rel="noreferrer" target="_blank" className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-gray-100 text-gray-800 capitalize">Download Report</a>
                        : ''
                      }
                      {/* <span className="px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800 capitalize">Download</span> */}
                    </td>
                    {/* <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      Admin
                    </td> */}
                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                      <Link to={`/admin/projects/${row.id}`} className="text-indigo-600 hover:text-indigo-900">Manage</Link>
                    </td>
                  </tr>
                </tbody>
              ))}
              {data.projects.length === 0 && 
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td colSpan="4" className="py-6 text-center text-sm font-medium text-gray-500">No Projects Found</td>
                  </tr>
                </tbody>
              }
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminProjects
