import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router-dom'
import { WorldMap } from 'react-svg-worldmap'
import { CollectionIcon, StatusIcon, CalendarIcon, ExternalLinkIcon } from '../components/Icons'
import FundingChart from '../components/FundingChart'
import HourlyHeatmap from '../components/Charts/HourlyHeatmap'
import PieChart from '../components/Charts/PieChart'
import DateFormatter from '../components/Date'
import Countdown from 'react-countdown'
import Error from '../components/Error'
import { differenceInCalendarDays, min } from 'date-fns'
import { filter, orderBy } from 'lodash'
import AdminManageProjectRunReport from '../components/Admin/AdminManageProjectRunReport'
import ReportSummary from '../components/ReportSummary'

const Section = ({ title, subtitle, secondary, children, ...props }) => {
  return (
    <section {...props} className="text-sm sm:text-base border-b bg-white">
      <div className="container-fluid">
        <div className="px-2 py-4 sm:px-6 sm:py-8">
          <div className="flex justify-between items-center">
            <div>
              <h3 className="text-lg sm:text-2xl leading-6 font-medium text-gray-900">
                {title}
              </h3>
              {subtitle}
            </div>
            {secondary}
          </div>
          {children}
        </div>
      </div>
    </section>
  )
}

const Stat = ({ title, value, chip = null }) => {
  return (
    <div>
      <div className="px-2 py-4 sm:px-6 sm:py-6">
        <dt className="text-sm sm:text-lg font-medium text-gray-900">
          {title}
        </dt>
        <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
          <div className="flex items-baseline text-2xl sm:text-3xl font-semibold text-indigo-600">
            {value}
          </div>
          {chip &&
          <div className="inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium bg-green-100 text-green-800 md:mt-2 lg:mt-0">
            {chip}
          </div>
           }
        </dd>
      </div>
    </div>
  )
}

const tabs = [
  { hash: "top", label: "Overview" },
  { hash: "funding", label: "Funding" },
  { hash: "stats", label: "Stats" },
  { hash: "pledges", label: "Pledges" },
  { hash: "community", label: "Community" },
  { hash: "performance", label: "Performance" },
]

const classes = {
  fundingChartTypeButton: 'text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-md',
  fundingChartTypeButtonSelected: 'bg-indigo-100 text-indigo-700 px-3 py-2 font-medium text-sm rounded-md',
}

function ViewProject () {
  const { id } = useParams()
  const [tab, setTab] = React.useState('top')
  const [fundingChartType, setFundingChartType] = React.useState('funding')
  
  const { data: { project } = {}, loading } = useQuery(gql`
    query($id:uuid!){
      project:projects_by_pk(id:$id){
        id
        name
        community
        data
        funding_graph_data
        historical:project_stats(order_by: {date: asc}) {
            date
            backers
            pledged
          }
      }
    }
  `, {
    errorPolicy: 'ignore',
    skip: !id,
    variables: {
      id
    }
  })

  const changeTab = React.useCallback(
    (e, hash) => {
      if (e) {
        e.preventDefault()
      }
      const id = hash.replace('#', '')
      setTab(id)
      setTimeout(() => {
        const element = document.getElementById(id)
        if (element) element.scrollIntoView()
      }, 0)
    },
    [setTab]
  )

  const changeSelect = React.useCallback(
    (e) => {
      e.preventDefault()
      const id = e.target.value
      setTab(id)
      setTimeout(() => {
        const element = document.getElementById(id)
        if (element) element.scrollIntoView()
      }, 0)
    },
    [setTab]
  )

  const usd = true

  const formatNumber = React.useCallback(
    (value, { currency = false, digits = 0 } = {}) => {
      const toLocale = (value = 0) => {
        return (value || 0).toLocaleString(undefined, {
          minimumFractionDigits: digits,
          maximumFractionDigits: digits,
        })
      }
      if (!currency) {
        return toLocale(value)
      } else {
        const isNegative = (value < 0)
        return (isNegative ? '-' : '') + (
          usd 
            ? '$' + toLocale(Math.abs(value * project.data.usd_exchange_rate))
            : project.data.currency_symbol + toLocale(value)
        )
      }
    },
    [project, usd]
  )

  const daysElapsed = React.useMemo(
    () => {
      if (!project) {
        return 0
      }
      return differenceInCalendarDays(
        min([
          new Date(),
          new Date(project.data.deadline * 1000)
        ]),
        new Date(project.data.launched_at * 1000)
      )
    },
    [project]
  )
  
  const rewards = React.useMemo(
    () => {
      if (!project) return []
      const rewards = orderBy(filter(project.data.rewards, o => o.id !== 0), 'backers_count', 'desc')
      const total = rewards.reduce((acc, o) => (acc + o.backers_count), 0)
      return rewards.map(o => {
        return {...o, ...{
          share: (o.backers_count / total)
         }
        }
      })
    },
    [project]
  )

  const topRewards = React.useMemo(
    () => {
      return rewards.slice(0, 3)
    },
    [rewards]
  )

  const otherRewards = React.useMemo(
    () => {
      return rewards.slice(3)
    },
    [rewards]
  )


  if (loading) {
    return <div />
  }

  if (!project) {
    return <Error message="Project Not Found" />
  }

  console.log(project.data)
  
  return (
    <div>
    
      <div className="hidden sm:block bg-white border-b">
        <div className="container-fluid py-2">
          <div className="sm:hidden">
            <select value={tab} onChange={changeSelect} id="tabs" name="tabs" className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md text-sm">
            {tabs.map(({ hash, label }) => (
              <option key={hash} value={hash}>{label}</option>
            ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <nav className="flex space-x-4" aria-label="Tabs">
            {tabs.map(({ hash, label }) => (
              <a key={hash} href={window.location.pathname + `#${hash}`} onClick={e => changeTab(e, hash)} 
                className="text-gray-500 hover:text-gray-700 px-3 py-2 font-medium text-sm rounded-m"
                >
                {label}
              </a>
            ))}
            </nav>
          </div>
        </div>
      </div>

      
      <div className="bg-white">
        <div className="border-b">
          <div className="container-fluid p-6 sm:p-12 lg:p-12">
            <div className="sm:flex sm:items-center sm:justify-between">
              <div className="sm:flex sm:space-x-8">
                <div className="flex-shrink-0">
                  <img className="mx-auto w-80 sm:w-80 object-contain" src={project.data.photo.full} alt={project.name} />
                </div>
                <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                  <p className="text-xl font-extrabold tracking-tight text-gray-800 sm:text-2xl xl:text-3xl leading-tight  mb-2">
                    {project.name}
                  </p>
                  <p className="text-sm sm:text-base  text-gray-500">
                    {project.data.blurb}
                  </p>

                  <dl className="mt-8 flex flex-col sm:mt-4 sm:flex-row sm:flex-wrap px-2 sm:px-0">
                    <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6 pb-1">
                      <CollectionIcon className="w-5 h-5 mr-1" />{project.data.category.name}
                    </dd>
                    <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6 pb-1">
                      <StatusIcon className="w-5 h-5 mr-1" />
                      {project.data.state}
                    </dd>
                    <dd className="flex items-center text-sm text-gray-500 font-medium sm:mr-6 pb-1">
                      <CalendarIcon className="w-5 h-5 mr-1" />
                      Ends on&nbsp; <DateFormatter format="MMM d" date={project.data.deadline * 1000} />&nbsp;
                      <Countdown 
                        date={project.data.deadline * 1000}
                        renderer={({ days, hours, minutes, seconds }) => {
                          if (days > 0) {
                            return `(${days} days)`
                          }
                          return `(${hours}h ${minutes}m)`

                        }}
                      />
                    </dd>
                    <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6 pb-1">
                      <ExternalLinkIcon className="w-5 h-5 mr-1" />
                      <a href={project.data.urls.web.project} target="_blank" rel="noreferrer" className="text-indigo-400">
                        View Project
                      </a>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 border-b">
          <div className="container-fluid" >
            <div className="border-gray-200 grid grid-cols-1 sm:grid-cols-3 divide-y divide-gray-200 sm:divide-y-0 sm:divide-x">
              <Stat 
                title="Total Funding"
                value={
                  <>
                    {formatNumber(project.data.pledged, { currency: true })}
                    <span className="ml-1 text-sm sm:text-base font-medium text-gray-400">
                      of {formatNumber(project.data.goal, { currency: true })}
                    </span>
                  </>
                }
                chip={parseInt(project.data.pledged / project.data.goal * 100) + '%'}
                />
              <Stat 
                title="Backers"
                value={project.data.backers_count} 
                chip={formatNumber(project.data.pledged / project.data.backers_count, { currency: true }) + ' avg pledge'}
              />
            {project.data.state === 'live'
              ?
                <Stat 
                  title="Time Remaining"
                  value={
                    <Countdown 
                      date={project.data.deadline * 1000}
                      renderer={({ days, hours, minutes, seconds }) => {
                        if (days > 0) {
                          return `${days} days ${hours} hours`
                        }
                        return `${hours}h ${minutes}m`

                      }}
                    />
                  }
                />
              : 
                <Stat 
                  title="Project Status"
                  value={<span className="capitalize">{project.data.state}</span>}
                />
            }
            </div>
          </div>
        </div>
      </div>

      <div>
        <div className="container-fluid">
          <div className="px-2 py-4 sm:px-6 sm:py-8">
            <ReportSummary project={project} onViewFullReport={() => changeTab(null, 'performance')} autoRun />
          </div>
        </div>
      </div>

      <div className="">

        <div>

          <Section 
            id="funding"
            title="Funding"
            subtitle="Daily totals"
            secondary={
              <nav className="flex space-x-0 sm:space-x-2" aria-label="Tabs">
                <button onClick={() => setFundingChartType('funding')} className={fundingChartType === 'funding' ? classes.fundingChartTypeButtonSelected : classes.fundingChartTypeButton} aria-current="page">
                  Funding
                </button>
                <button onClick={() => setFundingChartType('backers')} className={fundingChartType === 'backers' ? classes.fundingChartTypeButtonSelected : classes.fundingChartTypeButton}>
                  Backers
                </button>
              </nav>
            }
          >
            <div className="mt-4 sm:mt-8">
              <FundingChart 
                type={fundingChartType}
                project={project}
                formatNumber={formatNumber} 
              />
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 mt-2 sm:mt-8" id="stats">
              <div className="py-6">
                <h6 className="text:md font-medium sm:text-2xl text-center pb-4 sm:pb-1">Hourly heatmap</h6>
                <HourlyHeatmap
                  project={project}
                />
              </div>
              <div className="py-6">
                <h6 className="text:md font-medium sm:text-2xl text-center sm:text-left pb-4 sm:pb-1">Stats</h6>
                <div className="flex justify-between py-2 sm:py-2 border-b">
                  <div>Avg. backers/day</div>
                  <div>{formatNumber(project.data.backers_count / daysElapsed)}</div>
                </div>
                <div className="flex justify-between py-2 sm:py-2 border-b">
                  <div>Avg. funding/day</div>
                  <div>{formatNumber(project.data.pledged / daysElapsed, { currency: true })}</div>
                </div>
                <div className="flex justify-between py-2 sm:py-2 border-b">
                  <div>Avg. pledge</div>
                  <div>{formatNumber(project.data.pledged / project.data.backers_count, { currency: true })}</div>
                </div>
              </div>
            </div>
          </Section>

          <Section 
            id="pledges"
            title="Pledges"
            subtitle={
              <p className="mt-1 text-sm text-gray-500">
                Average Pledge: <span className="font-bold text-indigo-500">{formatNumber(project.data.pledged / project.data.backers_count, { currency: true })}</span>
              </p>
            }
          >
            <div className="grid grid-cols-1 md:grid-cols-2 sm:pb-4">
              <div className="py-4">

                <div className="border-b text-md pb-1 mb-4">Top Rewards</div>
                {topRewards.map((o, i) => (
                  <div key={o.id} className="flex space-x-3 pb-4">
                    <div className="w-20 border-r-4 border-gray-400 text-center">
                      <div className="text-2xl">{formatNumber(o.share * 100)}%</div>
                    </div>
                    <div>
                      <div className="font-medium">{o.title} ({formatNumber(o.minimum, { currency: true })})</div>
                      <div className="text-gray-400">{formatNumber(o.backers_count * o.minimum, { currency: true })} pledged</div>
                      <div className="text-gray-400">{o.backers_count} backers</div>
                    </div>
                  </div>

                ))}

              </div>
              <div className="hidden sm:block">
                
                <PieChart data={topRewards.map((o,i) => {
                  const colors = ['#0088FE', '#00C49F', '#FFBB28']
                  return {
                    label: o.title,
                    color: colors[i] || '#ffffff',
                    value: o.backers_count
                  }
                })} />
              </div>
            </div>

            <div className="border-b text-md pb-1 mb-4">All Rewards</div>
            <div className="grid grid-cols-1 md:grid-cols-3">
              {rewards.map((o, i) => (
                <div key={o.id} className="flex space-x-3 pb-4">
                  <div className="w-20 border-r-4 border-gray-400 text-center">
                    <div className="text-2xl">{formatNumber(o.share * 100)}%</div>
                  </div>
                  <div>
                    <div className="font-medium">{o.title} ({formatNumber(o.minimum, { currency: true })})</div>
                    <div className="text-gray-400">{formatNumber(o.backers_count * o.minimum, { currency: true })} pledged</div>
                    <div className="text-gray-400">{o.backers_count} backers</div>
                    {o.remaining > 0 && <div className="text-gray-400">{o.remaining} out of {o.limit} left ({o.backers_count} backers)</div>}
                  </div>
                </div>
              ))}
            </div>
            
          </Section>

{project.community && 
          <Section 
            id="community"
            title="Community"
            subtitle="Backers by countries"
          >
            <div className="grid grid-cols-1 md:grid-cols-2 sm:pb-4">
              <WorldMap 
                backgroundColor="#ffffff"
                color="#F68E20" 
                size="lg" 
                data={project.community.countries.map(o => ({ country: String(o.code), value: o.backers }))}
              />
              <div>
                {project.community.countries.map(({ name, code, backers }) => {
                  return (
                    <div key={name} className="flex justify-between py-2 sm:py-2 border-b">
                      <div>
                        {name}
                      </div>
                      <div>
                        <strong>{backers}</strong> ({parseInt(backers / project.data.backers_count * 100)}%)
                      </div>
                    </div>
                  )
                })}
              </div>
            </div> 

            <div className="grid grid-cols-1 md:grid-cols-2 py-6 sm:border-t mt-4">
              <div className="pb-6 sm:pb-0">
                <h6 className="text:md font-medium sm:text-2xl text-center">New vs Returning</h6>
                <PieChart data={[
                  { label: 'New', color: '#0088FE', value: project.community.new_backers, pct: (project.community.new_backers / project.data.backers_count) },
                  { label: 'Returning', color: '#00C49F',value: project.community.existing_backers, pct: (project.community.existing_backers / project.data.backers_count) },
                ]} />
              </div>
              <div className="pb-6 sm:pb-0">
              <h6 className="text:md font-medium sm:text-2xl text-center">Engagement</h6>
                <PieChart data={[
                  { label: 'Backers', color: '#0088FE', value: project.data.backers_count },
                  { label: 'Comments', color: '#00C49F',value: project.data.comments_count },
                ]} />
              </div>
            </div>
          </Section>

    }
          <Section 
            id="performance"
            title="Performance"
            subtitle="Ads and Marketing Performance"
          >
            <div className="mt-4">
              <AdminManageProjectRunReport project={project} autoRun />
            </div>
          </Section>

        </div>
      </div>

    </div>
  )
}

export default ViewProject
