import { gql, useLazyQuery, useMutation, useApolloClient } from '@apollo/client'
import React from 'react'
import NumberFormat from 'react-number-format'
import AdminManageFacebookView from './AdminManageFacebookView'

function AdminManageProjectFacebookView ({ project, onClose }) {
  const apolloClient = useApolloClient()
  const [syncLoading, setSyncLoading] = React.useState(false)

  const [sync] = useMutation(gql`
    mutation ($projectId: uuid!) {
      sync_project_stats_facebook(projectId: $projectId)
    }
  `)



  const handleSync = React.useCallback(
    async () => {
      if (window.confirm('This action will delete all Facebook data for this project and reload it using the current Facebook configuration.  OK?')) {
        setSyncLoading(true)
        const res = await sync({
          variables: {
            projectId: project.id
          }
        })

        const id = res.data.sync_project_stats_facebook

        let result = false
        const sleep = (ms) => (new Promise(resolve => setTimeout(resolve, ms)))

        do {
          await sleep(1000)

          const updateRes = await apolloClient.query({
            query: gql`
              query ($id:uuid!){
                sync_project_stats_facebook(id: $id){
                  id
                  errors
                  output{
                    success
                  }
                }
              }
            `,
            variables: {
              id
            }
          })

          result = updateRes.data.sync_project_stats_facebook
          
        } while (!result.errors && !result.output)

        if (result.errors) {
          window.alert('An error occurred')
        }

        setSyncLoading(false)
        onClose()
        window.alert('FB data has been reloaded')
      }
    },
    [project, sync, setSyncLoading, onClose, apolloClient]
  )

  return (
    <div>
      <div className="bg-white border shadow-sm rounded mb-4">
        <div className="border-b px-4 py-4">
          <div className="flex justify-between align-center">
            <div className="text-lg font-medium text-gray-900">Facebook Integration</div>
            <div className="flex space-x-2">
              {/* {project.fb_details && 
                <button type="button" className="btn" onClick={pullData}>
                  {getReportLoading ? 'Loading...' : 'Pull Live Data'}
                </button>
              } */}
              <button type="button" className="btn" onClick={handleSync} disabled={syncLoading}>
                {syncLoading ? 'Loading...' : 'Full Sync'}
              </button>
              <AdminManageFacebookView project={project} onClose={onClose} />
            </div>
          </div>
        </div>
        <div className="px-4 py-4">
        {project.fb_details 
          ? 
            <div className="text-sm">
              <div className="">{project.fb_details.account.name}</div>
              <div className="">
                <span className="text-gray-500">Ad Account:</span> {project.fb_details.adAccount.name} ({project.fb_details.adAccount.id})
              </div>
              <div className="">
                <span className="text-gray-500">Custom Conversion:</span> {project.fb_details.customConversion ? project.fb_details.customConversion.name : <span style={{color:'red', fontWeight: 'bold'}}>** NO CUSTOM CONVERSION **</span>}
              </div>
              <div className="">
                <span className="text-gray-500">Campaign Filter:</span> {project.fb_details.campaignFilter}
              </div>
              <div className="">
                <span className="text-gray-500">Currency:</span> {project.fb_details.adAccount.currency}
              </div>

              <div className="">
                <span className="text-gray-500">Ads Pixel: </span> 
                  {project.fb_details?.adsPixel
                    ? <>{project.fb_details?.adsPixel?.name} ({project.fb_details?.adsPixel?.id})</>
                    : 'Not Configured'
                  }
              </div>

            </div>
          : 
            <div className="text-sm text-center text-gray-400">
              No Facebook Account Access Configured
            </div>
        }

        </div>
      </div>
    </div>
  )
}

export default AdminManageProjectFacebookView 
