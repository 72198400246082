import React from 'react'
import { format, eachDayOfInterval } from 'date-fns'
import Card from '../Card'

function AdminManageProjectSyncStats ({ project }) {

  const weeks = React.useMemo(
    () => {
      const startDate = new Date(project.data.launched_at * 1000)
      const endDate = new Date(project.data.deadline * 1000)
      return eachDayOfInterval({ start: startDate, end: endDate },)
    },
    [project]
  )

  return (
    <Card 
      title="Project Syncs"
    >
    {/* {weeks.map(o => (
      <div key={o}>
        {format(o, 'yyyy-MM-dd')}
      </div>
    ))} */}
    </Card>
  )
}

export default AdminManageProjectSyncStats
