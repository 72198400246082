import { eachDayOfInterval, format, formatISO, isFuture, subHours } from 'date-fns';
import React from 'react';
import {
 Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, ComposedChart, Cell, Bar, Text, LabelList
} from 'recharts';
import _ from 'lodash'
import { useMediaQuery } from 'react-responsive'

// class CustomizedAxisTick extends React.PureComponent {
//   render() {
//     const {
//       x, y, stroke, payload,
//     } = this.props;

//     return (
//       <g transform={`translate(${x},${y})`}>
//         <text x={0} y={0} dy={16} textAnchor="end" fill="#666" transform="rotate(-35)">{payload.value}</text>
//       </g>
//     );
//   }
// }

// const CustomizedLabel = ({x, y, fill, value, formatter}) => {
//   if (!value) {
//     return null
//   }
//   return <Text 
//     x={x} 
//     y={y} 
//     dx={14}
//     dy={value > 0 ? -5 : -5} 
//     angle={-90}
//     fontSize={10}
//     fill={value < 0 ? '#ffffff' : fill}
//     >{(value < 0 ? '-' : '') + formatter(Math.abs(value))}</Text>
// }

function FundingChart ({ type, project, formatNumber }) {

  const isMobile = useMediaQuery({ query: '(max-width: 640px)' })
  
  // const [type] = React.useState('funding')

  // const height = 520 
  
  // React.useMemo(
  //   () => sm ? 800 : 520,
  //   [sm]
  // )

  const margin = React.useMemo(
    () => isMobile 
      ? {top: 24, right: -8, left: -16, bottom: 16} 
      : {top: 24, right: 0, left: 0, bottom: 24},
    [isMobile]
  )

  const axisTick = React.useMemo(
    () => isMobile 
      ? { fontSize: 10 } 
      : { fontSize: 13 },
    [isMobile]
  )

  // const lastUpdated = React.useMemo(
  //   () => {
  //     return <>Last updated now</>
  //   },
  //   []
  // )

  const axisFormatter = React.useCallback(
    (value) => {
      if (value >= 1000 || value <= -1000) {
        return (value / 1000).toLocaleString(undefined, {
          maximumFractionDigits: 0
        }) + 'k'
      }
      return value
    },
    []
  )

  const chartData = React.useMemo(
    () => {

      let prev_backers = null
      let prev_pledged = null

      // we store states at the top of the hour, so adjust for previous hour
      const formatDate = (str) => formatISO(
        subHours(new Date(str), 1), 
        { representation: 'date' }
      )

      if (project.funding_graph_data) {
        let previous_pledged = 0;
        let previous_backers = 0;
        let total_backers = 0;
        let total_pledged = 0;

        const data = []

        for (let o of project.funding_graph_data) {
          const backers = (o.b - previous_backers);
          total_backers += backers;


          const pledged = (o.l - previous_pledged);
          total_pledged += pledged;

          previous_pledged = o.l;
          previous_backers = o.b;
          const date = new Date(o.d + (60 * 60 * 6 * 1000));
          if (!isFuture(date) && backers > 0) {
            data.push({
              date, // formatISO(new Date(o.d + (60 * 60 * 6 * 1000)), { representation: 'date' }),
              backers,
              pledged,
              total_backers,
              total_pledged
            })
          } else if (isFuture(date)) {
            data.push({ 
              date, // formatISO(new Date(o.d + (60 * 60 * 6 * 1000)), { representation: 'date' }),
              backers: null,
              pledged: null,
              total_backers: null,
              total_pledged: null
            })
          } else {
            data.push({ 
              date, // formatISO(new Date(o.d + (60 * 60 * 6 * 1000)), { representation: 'date' }),
              backers: 0,
              pledged: 0,
              total_backers: previous_backers,
              total_pledged: previous_pledged
            })
          }
        }

        console.log(data);

        return data;
      }

      // group them
      const groups = _.groupBy(project.historical, o => formatDate(o.date))

      // fill any missing
      eachDayOfInterval({
        start: new Date(project.data.launched_at * 1000), 
        end: new Date(project.data.deadline * 1000)
      }).forEach(dateObj => {
        if (isFuture(dateObj)) {
          const date = formatDate(dateObj)
          if (!groups[date]) {
            groups[date] = []
          }
        }
      })
      
      const days = []
      _.forEach(groups, (items, date) => {
        const last = _.last(items)
        if (items.length === 0) {
          days.push({
            date,
            backers: null,
            pledged: null,
            total_backers: null,
            total_pledged: null
          })
        } else {
          days.push({
            date,
            backers: (last.backers - prev_backers),
            pledged: (last.pledged - prev_pledged),
            total_backers: last.backers,
            total_pledged: last.pledged
          })
          prev_backers = last.backers
          prev_pledged = last.pledged
        }

      })

      return _.orderBy(days, 'date') // make sure we enforce the order here
    },
    [project]
  )

  const domain = React.useMemo(
    () => {
      let max = 0
      const key = (type === 'funding' ? 'pledged' : 'backers')
      for (let item of chartData) {
        if (item[key] > max) max = item[key]
      }
      return ['auto', max * 1.2]
    },
    [type, chartData]
  )

  const renderCustomizedLabel = React.useMemo(
    () => {
      return ({ x, y, width, height, value }) => {
        const fontSize = 12  
        const _x = x + (width / 2)
        const _y = value > 0 ? (y - (fontSize / 2)) : y+height-(fontSize/2)
        return (
          <g>
            <Text fill={value < 0 ? '#F68E20' : '#294059'} fillOpacity={.60} x={_x} y={_y} width={width} height={height} textAnchor="start" verticalAnchor="middle" angle={-90} fontSize={12}>
              {formatNumber(value, { currency: type === 'funding', digits: 0 })}
            </Text>
          </g>
        )
      }
    },
    [formatNumber, type]
  )

  if (!chartData || chartData.length === 0) {
    return (
      <div className="flex border h-64 items-center justify-center">
        <div className="text-gray">No funding data available...</div>
      </div>
    )
  }

  return (
    <ResponsiveContainer width="100%" aspect={isMobile ? 2 : 2.5}>
      <ComposedChart 
        data={chartData}
        margin={margin}
        style={{ backgroundColor: isMobile ? '#f4f4f4' : 'transparent' }}
        
      >
        <CartesianGrid 
          stroke="#ddd"
          vertical={false}
        />
        <XAxis 
          dataKey="date" 
          tickFormatter={v => {
            const date = new Date(v)
            return isMobile
              ? format(date, 'MM/dd')
              : format(date, 'MMM do')
          }}
          angle={-45} 
          textAnchor="end"
          tick={axisTick}
          stroke="#888"
          axisLine={false}
          tickLine={false}
        />
        <YAxis
          tick={axisTick}
          tickFormatter={axisFormatter} 
          axisLine={true}
          stroke="#888"
          tickLine={true}
          tickCount={9}
          domain={domain}
        />
        <YAxis 
          tick={axisTick}
          yAxisId="total" 
          stroke="#888"
          orientation="right"
          tickFormatter={axisFormatter} 
          axisLine={false}
          tickLine={false}
          tickCount={9}
          // domain={[0, 120000]}
        />
        <Tooltip
          formatter={v => formatNumber(v, { currency: type === 'funding', digits: 0 })}
        />
        
        <Bar 
          name={type === 'funding' ? 'Daily Funding' : 'Daily Backers'} 
          dataKey={type === 'funding' ? 'pledged' : 'backers'} 
          // barSize={20}
          // label={!isMobile && <CustomizedLabel />}
          // label={barLabel}
          
        >
          {!isMobile && 
            <LabelList 
              dataKey={type === 'funding' ? 'pledged' : 'backers'} 
              position="top"
              angle={0}
              // dx={4}
              // dy={-24}
              fill="#666" 
              fontSize={12} 
              

              // fontWeight={600} 
              // formatter={type === 'funding' ? formatAmount : v => v}
              content={renderCustomizedLabel}
            />
          }
        {
          chartData.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry[type === 'funding' ? 'pledged' : 'backers'] > 0 ? "#294059":"#d62728"}/>
          ))
        }
        </Bar>
        <Line 
          connectNulls={true} 
          type="monotone" 
          name={type === 'funding' ? 'Total Pledged' : 'Total Backers'} 
          dot={false} 
          dataKey={type === 'funding' ? 'total_pledged' : 'total_backers'} 
          stroke="#F68E20" 
          strokeWidth={2} 
          yAxisId="total"
        />
      </ComposedChart>
    </ResponsiveContainer>
  )
}

export default FundingChart
