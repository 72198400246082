import React from 'react'
import Panel from '../Panel'
import Input from '../../components/Input'
import { gql, useMutation, useQuery } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import generator from 'generate-password'

const AddUserPanel = ({ show, onClose, onComplete }) => {
  const { register, handleSubmit, setValue, errors, reset } = useForm()

  React.useEffect(
    () => {
      if (show) {
        setValue('password', generator.generate({
          length: 8,
          numbers: true
        }))
      }
    },
    [show, setValue]
  )
  
  const [registerUser, { loading }] = useMutation(gql`
    mutation($email:String!, $password:String!, $role:String!){
      register(
        email:$email
        password:$password
        role:$role
      ){
        user{
          id
          email
        }
      }
    }
  `)

  const onSubmit = React.useCallback(
    async (values) => {
      const res = await registerUser({
        variables: {
          email: values.email,
          password: values.password,
          role: 'user'
        }
      })
      reset()
      onComplete(res.data.register.user)
    },
    [reset, onComplete, registerUser]
  )

  return (
    <Panel 
      title="Create User" 
      show={show}
      onClose={onClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mb-2">
          <Input 
            type="email" 
            name="email" 
            label="Email Address"
            autoComplete="off"
            autoFocus
            ref={register({ required: true })}
          />
          {errors.email && <span>This field is required</span>}
        </div>
        <div className="mb-2">
          <Input
            type="text"
            name="password"
            label="Password"
            autoComplete="off"
            ref={register({ required: true })}
          />
          {errors.password && <span>This field is required</span>}
        </div>
        <div className="flex-shrink-0 border-gray-200 ">
          <div className="space-x-3 flex justify-between">
          {loading
            ? <div>Loading...</div>
            :
            <button type="submit" className="btn">
              Create User
            </button>
          }
          </div>
        </div>
      </form>
    </Panel>
  )
}

function AdminAssignUserToProject ({ projectId, onClose }) {
  const history = useHistory()
  const { register, handleSubmit, setValue, watch  } = useForm()
  const [show, setShow] = React.useState(false)
  const [loading, setLoading] = React.useState(false)
  const [showCreateUser, setShowCreateUser] = React.useState(false)

  const { data:users, refetch:refetchUsers } = useQuery(gql`
    query{
      users(
        where:{
          role:{
            _eq:user
          }
        }
        order_by:{email:asc}
      ){
        id
        email
      }
    }
  `, {
    skip: !show
  })
  
  const [addToProject] = useMutation(gql`
    mutation($userId:uuid!, $projectId:uuid!){
      insert_users_projects_one(
        object:{
          userId:$userId
          projectId:$projectId
        }
        on_conflict: {
          constraint: users_projects_userId_projectId_key, 
          update_columns: []
        }
      ){
        id
      }
    }
  `)


  const handleClose = React.useCallback(
    () => {
      setShow(false)
      onClose()
    },
    [onClose, setShow]
  )

  const handleUserAdded = React.useCallback(
    async (user) => {
      await refetchUsers()
      setTimeout(() => {
        setValue('userId', user.id)
      }, 1)
      setShowCreateUser(false)
    },
    [setValue, setShowCreateUser, refetchUsers]
  )

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        const { data } = await addToProject({
          variables: {
            userId: values.userId,
            projectId
          }
        })
        console.log(data)
        handleClose()
        // // history.push(`/admin/projects/${data.track_project.projectId}`)
        // console.log(data)
      } catch (e) {
        alert('An error occured while trying to load this campaign.  Please check the URL and try again.')
      }
    },
    [handleClose, projectId, addToProject]
  )

  const selectedUserId = watch('userId')
  console.log('selectedUserId = %s', selectedUserId)

  return (
    <div>
      <button type="button" className="btn" onClick={() => setShow(true)}>
        Add User
      </button>
    
      <Panel 
        title="Add User to Project"
        show={show}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit(onSubmit)}>

          <div className="mb-3">
            <label htmlFor="location" className="block text-sm font-medium text-gray-700">Email Address</label>
            <select 
              defaultValue={null}
              id="location" name="userId" className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              ref={register({ required: true })}
              >
              <option value=""></option>
            {users && users.users.map(o => (
              <option key={o.id} value={o.id}>{o.email}</option>
            ))}
            </select>
          </div>

          <div className="flex-shrink-0 border-gray-200 ">
            <div className="space-x-3 flex justify-between">
              <button type="button" onClick={() => setShowCreateUser(true)} className="btn">
                Create User
              </button>
            {loading
              ? <div>Loading...</div>
              :
              <button type="submit" className="btn">
                Add User to Project
              </button>
            }
            </div>
          </div>
        </form>
      </Panel>
      <AddUserPanel 
        show={showCreateUser} 
        onClose={() => setShowCreateUser(false)}
        onComplete={handleUserAdded}
       />
    </div>
  )
}

export default AdminAssignUserToProject
