import React from 'react'
import Panel from '../Panel'
import { useForm } from 'react-hook-form'
import Input from '../../components/Input'
import { gql, useMutation, useQuery } from '@apollo/client'
import _ from 'lodash'
import Textarea from '../Textarea'

function AdminManageGAView ({ project, onClose }) {
  const { register, handleSubmit, reset, errors, setValue, getValues } = useForm()
  const [show, setShow] = React.useState(false)
  const [loading] = React.useState(false)

  const { loading: gaViewsLoading, data: gaViewsResponse } = useQuery(gql`
    query($project_id:uuid){
      ga_views(project_id:$project_id){
        data
      }
    }
  `, {
    skip: !show,
    variables: {
      project_id: project.id
    }
  })

  React.useEffect(
    () => {
      if (project.ga_details && !gaViewsLoading) {
        setValue('view_id', project.ga_details.view && project.ga_details.view.id)
        setValue('go2_links_source_filter', project.ga_details.go2_links_source_filter)
        setValue('google_ads_campaign_filter', project.ga_details.google_ads_campaign_filter)
        setValue('google_ads_campaign_exact', project.ga_details.google_ads_campaign_exact)
        setValue('go2_links_exclude', project.ga_details.go2_links_exclude)
      }
    },
    [show, project, setValue, gaViewsLoading]
  )

  const [update] = useMutation(gql`
    mutation ($id:uuid!, $ga_details:jsonb){
      update_projects_by_pk(
        pk_columns:{id:$id}
        _set:{
          ga_details:$ga_details
        }
      ){
        id
      }
    }
  `)

  const handleClose = React.useCallback(
    () => {
      console.log('handleClose')
      setShow(false)
      onClose()
      reset()
    },
    [onClose, setShow, reset]
  )

  const onSubmit = React.useCallback(
    async (values) => {
      try {
        // get the view
        const ga_view = _.find(gaViewsResponse.ga_views.data, o => o.view.id === values.view_id)
        delete values.view_id
        
        await update({
          variables: {
            id: project.id,
            ga_details: {...values, ...ga_view}
          }
        })

        handleClose()
      } catch (e) {
        console.log(e)
        alert('An error occured while trying to load this campaign.  Please check the URL and try again.')
      }
    },
    [gaViewsResponse, update, project, handleClose]
  )

  if (gaViewsLoading) {
    return <div>...</div>
  }

  return (
    <div>
      <button type="button" className="btn" onClick={() => setShow(true)}>
        Configure
      </button>
    
      <Panel 
        title="Configure Google Analytics"
        show={show}
        onClose={handleClose}
      >
        <form onSubmit={handleSubmit(onSubmit)}>

          <div className="mb-4">
            <label htmlFor="view_id" className="block text-sm font-medium text-gray-700">Google Service Account</label>
            <Input type="text" readOnly disabled value={project.google_service_account.email} />
            {/* <div className="text-xs">{}</div> */}
          </div>

          <div className="mb-4">
            <label htmlFor="view_id" className="block text-sm font-medium text-gray-700">Google Analytics View {gaViewsLoading ? ' (loading...)': ''}</label>
            <select 
              id="view_id" 
              name="view_id" 
              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              ref={register({ required: true })}
            >
              <option value="">Choose a view</option>
            {gaViewsResponse && gaViewsResponse.ga_views &&
              gaViewsResponse.ga_views.data.map(o => {
                return (
                  <option key={o.view.id} value={o.view.id}>
                    {`${o.property.name} / ${o.view.name} (${o.account.name})`}
                  </option>
                )
              })
            }
            </select>
          </div>

          <div className="text-sm font-medium">Import Go2.Fund Links</div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <div className="mb-4">
                <Input 
                  type="text" 
                  name="go2_links_source_filter" 
                  label="Go2.Links (Source Match Filter)"
                  autoComplete="off"
                  defaultValue={"go2.fund"}
                  ref={register({ required: true })}
                />
                {errors.go2_links_source_filter && <span>This field is required</span>}
              </div>
            </div>
            <div>
              <Textarea 
                name="go2_links_exclude" 
                label="Exclude go2.fund links (1 per line):"
                rows={4}
                placeholder=""
                ref={register({ required: false })}
              />
              {errors.go2_links_exclude && <span>This field is required</span>}
            </div>
          </div>

          <div className="text-sm font-medium">Import GA Ads</div>
          <div className="text-sm text-gray-800 mb-4">
            If you specify a contains and exact match, the importer will match as "AND".
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <Input 
                type="text" 
                name="google_ads_campaign_filter" 
                label="Campaign contains:"
                autoComplete="off"
                placeholder=""
                ref={register({ required: false })}
              />
              {errors.google_ads_campaign_filter && <span>This field is required</span>}
            </div>
            <div>
              <Textarea 
                name="google_ads_campaign_exact" 
                label="Campaigns exact (1 per line):"
                rows={4}
                placeholder=""
                ref={register({ required: false })}
              />
              {errors.google_ads_campaign_exact && <span>This field is required</span>}
            </div>
          </div>

          <div className="rounded-md bg-yellow-50 p-4 mb-4 mt-4">
          <div className="flex">
            <div>
              <h3 className="text-sm font-medium text-yellow-800">
                Attention
              </h3>
              <div className="mt-2 text-sm text-yellow-700">
                <p>
                  If you modify any of these values, make sure to click the "Full Sync" button to pull in new changes.
                </p>
              </div>
            </div>
          </div>
        </div>

          <div className="flex-shrink-0 border-gray-200 ">
            <div className="space-x-3 flex justify-between">
            {loading
              ? <div>Loading...</div>
              :
              <button type="submit" className="btn" disabled={gaViewsLoading}>
                Save
              </button>
            }
            </div>
          </div>




        </form>
      </Panel>
    </div>
  )
}

export default AdminManageGAView
