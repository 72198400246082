import React from 'react'

function Panel ({ show, onClose, title, children, }) {
  
  return (
    <div className={`${show ? 'visible' : 'invisible'} fixed inset-0 overflow-hidden`}>
      <div className="absolute inset-0 overflow-hidden" style={{backgroundColor:'rgba(0,0,0,.1)'}}>
        <section className="absolute inset-y-0 right-0 pl-10 max-w-full flex" aria-labelledby="slide-over-heading">
          <div className="w-screen max-w-xl">
            <div className="h-full flex flex-col bg-white shadow-xl overflow-y-scroll">

              <div className="px-4 sm:px-6 bg-gray-100 py-4">
                <div className="flex items-start justify-between">
                  <h2 id="slide-over-heading" className="text-lg font-medium text-gray-900">
                    {title}
                  </h2>
                  <div className="ml-3 h-7 flex items-center">
                    <button
                      onClick={onClose}
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Close panel</span>
                      <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>

              <div className="mt-6 relative flex-1 px-4 sm:px-6">
                <div className="absolute inset-0 px-4 sm:px-6">
                  <div className="h-full">{children}</div>
                </div>
              </div>

            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default Panel
