import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { Link } from 'react-router-dom'
import Input from '../components/Input'
import { debounce } from 'lodash'

const ProjectCard = ({ children }) => {
  return (
    <div className="relative overflow-hidden rounded-lg border border-gray-300 bg-white shadow-sm hover:border-indigo-400 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
      {children}
    </div>
  )
}

function Dashboard () {
  const [filter, setFilter] = React.useState(null)
  const [filterActive, setFilterActive] = React.useState(true)

  const debouncedSave = React.useRef(
    debounce(
      (e) => {
        const value = e.target.value
        console.log('value = %s', value)
        if (value.length === 0) {
          setFilter(null)
        } else {
          setFilter(`%${value}%`)
        }
      },
      400
    )
  ).current
  
  const { data, loading } = useQuery(gql`
    query getDashboardProjects ($filter:String, $enabled:Boolean) {
      projects(
        order_by:{createdAt:desc}
        where:{
          name:{
            _ilike:$filter
          }
          enabled: {
            _eq: $enabled
          }
        }
      ){
        id
        name
        state:data(path:"state")
        photos:data(path:"photo")
        blurb:data(path:"blurb")
      }
    }
  `, {
    pollInterval: 30000,
    variables: {
      filter,
      enabled: filterActive
    }
  })

  if (loading && !data) {
    return <div />
  }

  return (
    <div className="container-fluid py-2 md:py-4">
      <div className="hidden sm:block">
        <h1 className="mb-2 text-xl text-center sm:text-left leading-8 font-extrabold tracking-tight text-gray-900 sm:text-3xl">Projects</h1>
      </div>

      <div className="flex space-x-4 mb-2">
        <div className="form-check form-check-inline">
          <input 
            className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 cursor-pointer" 
            type="radio" 
            id="inlineRadio1" 
            onChange={e => setFilterActive(true)}
            checked={filterActive === true}
          />
          <label className="form-check-label inline-block text-gray-800" htmlFor="inlineRadio1">Active</label>
        </div>
        <div className="form-check form-check-inline">
          <input 
            className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-1 cursor-pointer" 
            type="radio" 
            id="inlineRadio2" 
            onChange={e => setFilterActive(false)}
            checked={filterActive === false}
          />
          <label className="form-check-label inline-block text-gray-800" htmlFor="inlineRadio2">Archived</label>
        </div>
      </div>

      <div className="mb-3">
        <Input type="text" placeholder="Filter projects by name..." onChange={debouncedSave} autoFocus />
      </div>
      <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3">
      {data.projects.map(o => (
        <ProjectCard key={o.id}>
          <div>
            <img className="h-36 w-full object-cover object-top" src={o.photos.full} alt={o.name} />
          </div>
          <div className="flex p-4 space-x-3">
            <div className="flex-1 min-w-0 ">
              <Link to={`/p/${o.id}`} className="focus:outline-none">
                <span className="absolute inset-0" aria-hidden="true"></span>
                <p className="text-base font-medium text-gray-900 leading-5 sm:leading-normal mb-2">
                  {o.name}
                </p>
                <p className="text-sm text-indigo-500 truncate">
                  {/* <span className=" text-gray-700">State:</span>  */}
                  <span className="font-medium capitalize">{o.state}</span>
                </p>
              </Link>
            </div>
          </div>
        </ProjectCard>
      ))}
      </div>

      {data.projects.length === 0
        && <div className="text-center text-sm text-gray-600">No projects found</div>
      }

    </div>
  )
}

export default Dashboard
