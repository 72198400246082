import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import AppLayout from './layouts/AppLayout'
import Auth from './views/Auth'
import Dashboard from './views/Dashboard'
import ViewProject from './views/ViewProject'
import SignOut from './views/SignOut'
import NoMatch from './views/NoMatch'

import AdminLayout from './layouts/AdminLayout'
import AdminIndex from './views/Admin/Index'
import AdminUsers from './views/Admin/AdminUsers'
import AdminProjects from './views/Admin/AdminProjects'
import AdminViewProject from './views/Admin/AdminViewProject'


import useAuth from './hocs/AuthProvider'

function AppRoute ({ component, layout, ...props }) {
  const Component = component
  const Layout = layout || AppLayout

  return (
    <Route {...props}>
      <Layout>
        <Component {...props} />
      </Layout>
    </Route>
  )
}

function AppRoutePrivate (props) {
  const { isAuthenticated } = useAuth()

  if (!isAuthenticated && props.path !== '/auth') {
    return <Redirect to="/auth?error=401" />
  }

  return (
    <AppRoute {...props} />
  )
}

function App () {
  return (
    <BrowserRouter>
      <Switch>
        <AppRoutePrivate path="/" component={Dashboard} exact />
        <AppRoutePrivate path="/p/:id" component={ViewProject} exact />

        <AppRoutePrivate path="/admin" component={AdminIndex} layout={AdminLayout} exact />
        <AppRoutePrivate path="/admin/users" component={AdminUsers} layout={AdminLayout} exact />
        <AppRoutePrivate path="/admin/projects" component={AdminProjects} layout={AdminLayout} exact />
        <AppRoutePrivate path="/admin/projects/:id" component={AdminViewProject} layout={AdminLayout} exact />

        <Route path="/auth" component={Auth} exact />
        <Route path="/sign-out" component={SignOut} exact />
        <Route path="*" component={NoMatch} status={404} />
      </Switch>
    </BrowserRouter>
  )
}

export default App
