
import React from 'react'
import { Cell, Legend, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts'

const data = [
  { name: 'Group A', value: 400 },
  { name: 'Group B', value: 300 },
  { name: 'Group C', value: 300 },
  { name: 'Group D', value: 200 },
];
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx, cy, midAngle, innerRadius, outerRadius, value, percent, index,
}) => {
   const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {/* {`${(percent * 100).toFixed(0)}%`} */}
      {value}
    </text>
  );
};

function Chart ({ data }) {

  return (
    <ResponsiveContainer width="100%" height={320}>
      <PieChart>
        <Pie 
          data={data} 
          dataKey="value" 
          nameKey="name" 
          cx="50%" 
          cy="50%" 
          labelLine={false}
          label={renderCustomizedLabel}
          innerRadius={60} 
          outerRadius={120} 
          fill="#82ca9d"
          paddingAngle={0} 
        >
        {
          data.map((entry, index) => <Cell name={entry.label} key={`cell-${index}`} fill={entry.color} />)
        }
        </Pie>
        <Legend />
        {/* <Legend
          payload={
            data.map(
              item => ({
                id: item.name,
                type: 'square',
                label: 'here',
                value: `${item.name} (${item.value}%)`,
              })
            )
          }
        /> */}
        <Tooltip />
      </PieChart>
    </ResponsiveContainer>
  )
}

export default Chart
