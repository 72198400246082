import React from 'react'
import Navbar from '../components/Navbar'

function AppLayout ({ children }) {
  return (
    <div id="top">
      <Navbar />
      {children}
    </div>
  )
}

export default AppLayout
