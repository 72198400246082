import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { ApolloLink } from '@apollo/client/link/core'
import { ApolloProvider as RawApolloProvider } from '@apollo/react-hooks'
// import useToken from '../hooks/useToken'
import useAuth from './AuthProvider'

export function apolloClientFactory ({ token }) {

  const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_HASURA_BASE_ENDPOINT}/v1/graphql`,
    headers: token ? { authorization: `Bearer ${token}` } : {}
  }) 

  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      console.log(graphQLErrors)
      graphQLErrors.forEach(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
    }
    if (networkError) {
      console.log(`[Network error]: ${networkError}`)
      console.log(networkError)

      if (
        networkError.message.indexOf('JWT') !== -1 || 
        networkError.message.indexOf('Authentication hook unauthorized this request') !== -1
      ) {
        // setToken(null) // sign out
        window.location.href = window.location.origin + '/sign-out'
      }

    }
  });

  return new ApolloClient({
    link: ApolloLink.from([errorLink, httpLink]),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      // query: {
      //   fetchPolicy: 'no-cache',
      //   errorPolicy: 'all',
      // },
      // mutate: {
      //   errorPolicy: 'all',
      // },
    },
    connectToDevTools: process.env.NODE_ENV === 'development'
  })
}

function ApolloProvider ({ children }) {
  const { token } = useAuth()

  // Recreate apolloClient when auth token changes
  const apolloClient = useMemo(
    () => apolloClientFactory({ token }),
    [token]
  )

  // Reset apollo cache when user logs out
  useEffect(
    () => {
      if (!token) {
        apolloClient.resetStore()
      }
    },
    [apolloClient, token]
  )

  return (
    <RawApolloProvider client={apolloClient}>
      {children}
    </RawApolloProvider>
  )
}

ApolloProvider.propTypes = {
  children: PropTypes.node
}

export default ApolloProvider
