import React from 'react'
import Panel from '../Panel'
import Input from '../../components/Input'
import { gql, resetApolloContext, useMutation } from '@apollo/client'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import generator from 'generate-password'

function AdminEditUser ({ user, onClose, onSave }) {
  const history = useHistory()
  const { register, handleSubmit, setValue, errors, reset } = useForm()
  const [show, setShow] = React.useState(false)
  const [loading, setLoading] = React.useState(false)

  const [updateUser] = useMutation(gql`
    mutation ($id:uuid!, $role:user_roles_enum){
      user:update_users_by_pk(
        pk_columns:{id:$id}
        _set:{
          role:$role
        }
      ){
        id
        role
      }
    }
  `)

  const [changePassword] = useMutation(gql`
    mutation ($userId:uuid!, $password:String!){
      change_password(
        userId:$userId
        password:$password
      ){
        success
      }
    }
  `)


  const handleClose = React.useCallback(
  () => {
      setShow(false)
      setLoading(false)
      reset()
      onClose()
    },
    [setShow, onClose, reset, setLoading]
  )
  

  const onSubmit = React.useCallback(
    async (values) => {
      setLoading(true)
      try {
        const { data } = await updateUser({
          variables: {
            id: user.id,
            role: values.role
          }
        })

        if (values.password && values.password.length > 0) {
          await changePassword({
            variables: {
              userId: user.id,
              password: values.password
            }
          })
        }

        onSave(data.user)
        handleClose()
      } catch (e) {
        console.log(e)
        alert('An error occured while trying to save this user.  Please check the URL and try again.')
        setLoading(false)
      }
    },
    [setLoading, updateUser, onSave, handleClose, user, changePassword]
  )

  React.useEffect(
    () => {
      console.log('user = %s', user)
      setShow(!!user)
    },
    [user, setShow]
  )
  
  return (
    <Panel 
      title="Edit User"
      show={show}
      onClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>

        <div className="pb-4">
          <Input 
            defaultValue={user && user.email}
            type="email" 
            name="email" 
            label="Email" 
            disabled
            autoComplete="off"
            ref={register({ required: true })}
          />
          {errors.email && <span>This field is required</span>}
        </div>

        <div className="pb-4">
          <Input 
            type="text" 
            name="password" 
            label="Password" 
            autoComplete="off"
            ref={register({ required: false })}
          />
          {errors.password && <span>This field is required</span>}
          <div className="text-sm py-1 text-gray-600">To change password, enter a new one and click Save</div>
        </div>

        <div className="pb-4">
          <label className="block text-sm font-medium text-gray-700">Role {user && user.role}</label>
          <select 
            defaultValue={user && user.role}
            name="role" className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            ref={register({ required: true })}
            >
            <option value="user" selected={user && user.role === 'user'}>User</option>
            <option value="admin" selected={user && user.role === 'admin'}>Admin</option>
          </select>
          {errors.role && <span>This field is required</span>}
        </div>

        <div className="flex-shrink-0 border-gray-200 py-4">
          <div className="space-x-3 flex justify-end">
          {loading
            ? <div>Loading...</div>
            :
            <button type="submit" className="btn">
              Save
            </button>
          }
          </div>
        </div>

        <div className="border-b text-md pb-1 mb-4">Assigned Projects</div>
        {user &&
          <div>
          {user.users_projects.map(o => (
            <div className="text-sm text-gray-600" key={o.id}>{o.project.name}</div>
          ))}
          {user.users_projects.length === 0 && <div className="text-sm text-gray-600">User is not assigned to any projects</div> }
          </div>
        }

      </form>
    </Panel>
  )
}

export default AdminEditUser
