import React from 'react'
import { Link } from 'react-router-dom'
import useAuth from '../hocs/AuthProvider'

const classes = {
  mobileNavLink: 'text-gray-300 hover:bg-gray-700 hover:text-white block px-3 py-2 rounded-md text-base font-medium'
}

function Navbar () {
  const { user } = useAuth()
  const [show, setShow] = React.useState(false)

  const isAdmin = React.useMemo(
    () => user && user.role === 'admin',
    [user]
  )

  React.useEffect(
    () => {
      setShow(false)
    },
    [setShow]
  )

  return (
    <nav className="bg-gray-800">
      <div className="container-fluid">
        <div className="relative flex items-center justify-between h-16">
          <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
            <button 
              onClick={() => setShow(!show)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" aria-expanded="false">
              <span className="sr-only">Open main menu</span>
              <svg className="block h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
              <svg className="hidden h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
          <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
            <div className="flex-shrink-0 flex items-center">
              <Link to="/">
                <img className="block lg:hidden h-8 w-auto" src="/nav-logo-only.png" alt="Logo" />
                <img className="hidden lg:block h-9 w-auto" src="/nav-logo.png" alt="Logo" />
              </Link>
            </div>
            <div className="hidden sm:block sm:ml-6">
              <div className="flex space-x-4">
                {/* <a href="#" className="bg-gray-900 text-white px-3 py-2 rounded-md text-sm font-medium">Projects</a> */}
                <Link to="/" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">All Projects</Link>
                {isAdmin && <Link to="/admin" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm font-medium">Admin</Link>}
              </div>
            </div>
          </div>
          
          <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">
            <div className="relative">
              <span className="hidden sm:inline text-gray-400 text-sm font-medium mr-3">Signed in as {user.email}</span>
              <Link to="/sign-out" className="text-gray-300 hover:bg-gray-700 hover:text-white px-3 py-2 rounded-md text-sm">Sign out</Link>
            </div>
          </div>
          
        </div>
      </div>

      {/* Mobile Nav */}
      <div className={`${show === true ? 'visible' : 'hidden'} sm:hidden`}>
        <div className="px-2 pt-2 pb-3 space-y-1">
          <Link to="/" className={classes.mobileNavLink} onClick={() => setShow(false)}>All Projects</Link>
          {isAdmin && <Link to="/admin" className={classes.mobileNavLink} onClick={() => setShow(false)}>Admin</Link>}
        </div>
      </div>

    </nav>
  )
}

export default Navbar
