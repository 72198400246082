import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useAuth from '../hocs/AuthProvider'
import { useForm } from 'react-hook-form'
import Alert from '../components/Alert'

function Auth() {
  const history = useHistory()
  const [remember, setRemember] = React.useState(true)
  const { signIn } = useAuth()
  const { register, handleSubmit } = useForm()
  const [error, setError] = React.useState('')
  const location = useLocation()

  React.useEffect(() => {
    if (location.search.indexOf('error=401') !== -1) {
      // setError('Please login to view that page.')
    }
  }, [location])

  const onSubmit = React.useCallback(
    async ({ email, password }) => {
      try {
        await signIn({
          email,
          password
        })
        setError('')
        history.push('/')
      } catch (err) {
        console.log('***')
        console.log(err)
        console.log('***')
        setError(err.message)
      }
    },
    [history, signIn, setError]
  )

  const handleForgetPassword = React.useCallback(
    (e) => {
      e.preventDefault()
      alert('To reset your password, please email a member of the Enventys Partners team.')
    },
    []
  )

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div>
          <img className="mx-auto h-12 w-auto" src="/nav-logo-only.png" alt="Enventys Partners" />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Sign in to your account
          </h2>
        </div>
        {error.length > 0 && <Alert type="error" message={error} dismissible />}
        <form onSubmit={handleSubmit(onSubmit)} className="mt-4 space-y-4">
          <div className="rounded-md shadow-sm -space-y-px">
            <div>
              <label htmlFor="email-address" className="sr-only">Email address</label>
              <input ref={register} id="email-address" name="email" type="email" autoComplete="email" required className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" autoFocus placeholder="Email address" />
            </div>
            <div>
              <label htmlFor="password" className="sr-only">Password</label>
              <input ref={register} id="password" name="password" type="password" autoComplete="current-password" required className="appearance-none rounded-none relative block w-full px-3 py-3 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password" />
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input id="remember_me" name="remember_me" type="checkbox" checked={remember} onChange={e => setRemember(e.target.checked)} className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded" />
              <label htmlFor="remember_me" className="ml-2 block text-sm text-gray-900">
                Remember me
              </label>
            </div>

            <div className="text-sm">
              <a href="/" onClick={handleForgetPassword} className="font-medium text-indigo-600 hover:text-indigo-500">
                Forgot your password?
              </a>
            </div>
          </div>

          <div>
            <button type="submit" className="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
              Sign in
            </button>
          </div>


          <div className="mt-6">
            <div className="relative flex justify-center text-sm">
              <span className="text-gray-500">
                Copyright&copy; 2021 - <a href="https://enventyspartners.com/" target="_blank" rel="noreferrer" className="text-indigo-600">Enventys Partners</a>
              </span>
            </div>
          </div>
          
        </form>
      </div>
    </div>
  );
}

export default Auth
