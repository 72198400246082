import React from 'react'
import { useHistory } from 'react-router-dom'
import useAuth from '../hocs/AuthProvider'

function SignOut () {
  const history = useHistory()
  const { signOut } = useAuth()

  React.useEffect(
    () => {
      signOut()
      setTimeout(() => {
        history.push('/auth')
      }, 600)
    },
    [history, signOut]
  )

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <div className="text-2xl text-gray-700">
        Signing out...
      </div>
    </div>
  )
}

export default SignOut
