import React from 'react';
import { Radar, RadarChart, PolarGrid, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip } from 'recharts'
import { format, subHours } from 'date-fns'
import groupBy from 'lodash/groupBy'
import forEach from 'lodash/forEach'
import sortBy from 'lodash/sortBy'

function Chart ({ project }) {

  const hourlyAgg = React.useMemo(
    () => {
      // we store states at the top of the hour, so adjust for previous hour
      const dateToHour = (str) => format(
        subHours(new Date(str), 1), 
        'HH'
      )

      const stats = []
      for (let i=0 ; i<project.historical.length ; i++) {
        const item = project.historical[i]
        const prev = project.historical[i-1]
        stats.push({
          hour: dateToHour(item.date),
          new_pledged: (item.pledged - (prev ? prev.pledged : 0))
        })
      }

      // group them
      const hours = []
      const groups = groupBy(stats, 'hour')
      forEach(groups, (items, hour) => {
        const value = items.reduce((acc, o) => (acc + o.new_pledged), 0)
        hours.push({
          hour,
          value,
          pct: Math.floor((value / project.data.pledged) * 100)
        })
      })

      console.log('total = %s', hours.reduce((acc, o) => (acc + o.value), 0))

      return sortBy(hours, o => parseInt(o.hour))
    },
    [project]
  )

  return (
    <ResponsiveContainer width="100%" height="100%" aspect={2}>
      <RadarChart cx="50%" cy="50%" outerRadius="80%" data={hourlyAgg}>
        <PolarGrid />
        <PolarAngleAxis dataKey="hour" tick={{ fontSize: 12}} />
        {/* <PolarRadiusAxis tick={{ fontSize: 12}} /> */}
        <Tooltip />
        <Radar name="%" dataKey="pct" stroke="#8884d8" fill="#8884d8" fillOpacity={0.6} />
      </RadarChart>
    </ResponsiveContainer>
  );
}


export default Chart 